import axios from "axios";
import {
  ACCOUNT_REQUEST,
  ACCOUNT_SUCCESS,
  ACCOUNT_FAIL,
  ACCOUNT_CREATE_REQUEST,
  ACCOUNT_CREATE_SUCCESS,
  ACCOUNT_CREATE_FAIL,
  ACCOUNT_CREATE_RESET,
  ACCOUNT_UPDATE_REQUEST,
  ACCOUNT_UPDATE_SUCCESS,
  ACCOUNT_UPDATE_FAIL,
  ACCOUNT_UPDATE_RESET,
  ACCOUNT_DELETE_REQUEST,
  ACCOUNT_DELETE_SUCCESS,
  ACCOUNT_DELETE_FAIL,
  // ACCOUNT_PERMISSION_REQUEST,
  // ACCOUNT_PERMISSION_SUCCESS,
  // ACCOUNT_PERMISSION_FAIL,
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAIL,
} from "../constants/accountConstant";

export const accountList = () => async (dispatch) => {
  try {
    dispatch({
      type: ACCOUNT_REQUEST,
    });

    const { data } = await axios.get(
      "http://stockmanagement.clonestudiobd.com/api/accounts"
    );

    dispatch({
      type: ACCOUNT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ACCOUNT_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const accountCreateAction =
  (
    user_id,
    account_type,
    account_name,
    opening_balance,
    address,
    mobile_no,
    email_address,
    photo,
    remarks,
    created_by,
    modified_by
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACCOUNT_CREATE_REQUEST,
      });

      const formData = new FormData();
      formData.append("user_id", user_id);
      formData.append("account_type", account_type);
      formData.append("account_name", account_name);
      formData.append("opening_balance", opening_balance);
      formData.append("address", address);
      formData.append("mobile_no", mobile_no);
      formData.append("email_address", email_address);
      formData.append("photo", photo);
      formData.append("remarks", remarks);

      console.log("formData", formData);

      const config = {
        headers: {
          // Accept: "application/json",
        },
      };

      const { data } = await axios
        .post(
          "http://stockmanagement.clonestudiobd.com/api/accounts",
          formData,
          config
        )
        .then((res) => {
          console.log(res.data);
        });

      console.log("testDAta", data);

      dispatch({
        type: ACCOUNT_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ACCOUNT_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const accountUpdateAction =
  (
    id,
    user_id,
    account_type,
    account_name,
    opening_balance,
    address,
    mobile_no,
    email_address,
    photo,
    remarks,
    created_by,
    modified_by
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACCOUNT_UPDATE_REQUEST,
      });

      console.log("id", id);

      console.log("user_id", user_id);
      console.log("account_type", account_type);
      console.log("account_name", account_name);
      console.log("opening_balance", opening_balance);
      console.log("address", address);
      console.log("mobile_no", mobile_no);
      console.log("email_address", email_address);
      console.log("photo", photo);
      console.log("remarks", remarks);

      const formData = new FormData();
      formData.append("user_id", user_id);
      formData.append("account_type", account_type);
      formData.append("account_name", account_name);
      formData.append("opening_balance", opening_balance);
      formData.append("address", address);
      formData.append("mobile_no", mobile_no);
      formData.append("email_address", email_address);
      formData.append("photo", photo);
      formData.append("remarks", remarks);

      console.log("Data", formData);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios
        .post(
          `http://stockmanagement.clonestudiobd.com/api/accounts/${id}`,
          {
            user_id: user_id,
            account_type: account_type,
            account_name: account_name,
            opening_balance: opening_balance,
            address: address,
            mobile_no: mobile_no,
            email_address: email_address,
            photo: photo,
            remarks: remarks,
          },
          config
        )
        .then((res) => {
          console.log("abhbcd", res.data);
        });

      console.log(
        "update data",
        user_id,
        account_type,
        account_name,
        opening_balance,
        address,
        mobile_no,
        email_address,
        photo,
        remarks,
        created_by,
        modified_by
      );

      dispatch({
        type: ACCOUNT_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ACCOUNT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const accountDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ACCOUNT_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(
      `http://stockmanagement.clonestudiobd.com/api/accounts/${id}`,
      config
    );

    dispatch({
      type: ACCOUNT_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: ACCOUNT_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

//USER

export const userList = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_REQUEST,
    });

    const { data } = await axios.get(
      "http://stockmanagement.clonestudiobd.com/api/users"
    );

    dispatch({
      type: USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// export const organizationPermissionAction =
//   (user_role_id, app_menu_id) => async (dispatch) => {
//     try {
//       dispatch({
//         type: ACCOUNT_PERMISSION_REQUEST,
//       });

//       const config = {
//         headers: {
//           Accept: "application/json",
//         },
//       };

//       console.log("DataCheck", user_role_id, app_menu_id);

//       const { data } = await axios.post(
//         "http://bbox.clonestudiobd.com/api/userrolefunctions/permission",
//         {
//           user_role_id: user_role_id,
//           app_menu_id: app_menu_id,
//         },
//         config
//       );

//       console.log("DataP", user_role_id, app_menu_id);

//       dispatch({
//         type: ACCOUNT_PERMISSION_SUCCESS,
//         payload: data,
//       });
//     } catch (error) {
//       dispatch({
//         type: ACCOUNT_PERMISSION_FAIL,
//         payload:
//           error.response && error.response.data.error
//             ? error.response.data.error
//             : error.error,
//       });
//     }
//   };
