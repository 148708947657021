import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
import {
  employeeList,
  employeeCreateAction,
} from "../../../redux/actions/employeeAction";

import { CreateValidation } from "./FormValidation";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <PersonAddIcon style={{ fontSize: 25 }} />
      <Typography
        variant="h6"
        style={{ marginLeft: 20, fontSize: 16, fontWeight: "500" }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Popup = (props) => {
  const {
    openPopup,
    setOpenPopup,
    employees,
    designations,
    shifts,
    setNotify,
  } = props;
  const [designation_id, setDesignationId] = React.useState("");
  const [shift_id, setShiftId] = React.useState("");

  const device_code = "123456";
  const device_validation = "Y";

  const dispatch = useDispatch();
  const employeeCreate = useSelector((state) => state.employeeCreate);
  const { employeeCreates } = employeeCreate;
  console.log("employee", employeeCreates);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    // if ("First_Name" in fieldValues)
    //   temp.First_Name = fieldValues.First_Name ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    CreateValidation(true, validate);

  const First_Name = values.First_Name;
  const last_name = values.last_name;
  const fathers_name = values.fathers_name;
  const monther_name = values.monther_name;
  const birth_date = values.birth_date;
  const join_date = values.join_date;
  const phone_no = values.phone_no;
  const email_address = values.email_address;
  const gross_salary = values.gross_salary;

  console.log(
    "post data",
    First_Name,
    last_name,
    fathers_name,
    monther_name,
    designation_id,
    shift_id,
    birth_date,
    join_date,
    phone_no,
    email_address,
    gross_salary
  );

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(
        employeeCreateAction(
          First_Name,
          last_name,
          fathers_name,
          monther_name,
          designation_id,
          shift_id,
          birth_date,
          join_date,
          phone_no,
          email_address,
          gross_salary
        )
      );
      setOpenPopup(false);
      setTimeout(() => {
        dispatch(employeeList());
      }, 2000);
      // setNotify({
      //   isOpen: true,
      //   message: 'Submitted Successfully!',
      //   type: 'success',
      // });
      resetForm();
    }
  };

  const handleClose = () => {
    setOpenPopup(false);
    resetForm();
  };

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={openPopup}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Create Form
      </DialogTitle>
      <DialogContent dividers>
        <InputForm
          setDesignationId={setDesignationId}
          setShiftId={setShiftId}
          employees={employees}
          designations={designations}
          shifts={shifts}
          value={values}
          handleInputChange={handleInputChange}
          error={errors}
          helperText={errors}
        />
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
