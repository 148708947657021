import {
  FUEL_TANK_REQUEST,
  FUEL_TANK_SUCCESS,
  FUEL_TANK_FAIL,
  FUEL_TANK_CREATE_REQUEST,
  FUEL_TANK_CREATE_SUCCESS,
  FUEL_TANK_CREATE_FAIL,
  FUEL_TANK_CREATE_RESET,
  FUEL_TANK_UPDATE_REQUEST,
  FUEL_TANK_UPDATE_SUCCESS,
  FUEL_TANK_UPDATE_FAIL,
  FUEL_TANK_UPDATE_RESET,
  FUEL_TANK_DELETE_REQUEST,
  FUEL_TANK_DELETE_SUCCESS,
  FUEL_TANK_DELETE_FAIL,
  FUEL_TANK_PERMISSION_REQUEST,
  FUEL_TANK_PERMISSION_SUCCESS,
  FUEL_TANK_PERMISSION_FAIL,
  PRODUCT_REQUEST,
  PRODUCT_SUCCESS,
  PRODUCT_FAIL,
} from "../constants/fueltankConstant";

export const fueltankReducer = (state = { fueltanks: [] }, action) => {
  switch (action.type) {
    case FUEL_TANK_REQUEST:
      return { loading: true, fueltanks: [] };
    case FUEL_TANK_SUCCESS:
      return { loading: false, fueltanks: action.payload };
    case FUEL_TANK_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const fueltankCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case FUEL_TANK_CREATE_REQUEST:
      return { loading: true };
    case FUEL_TANK_CREATE_SUCCESS:
      return { loading: false, fueltankCreate: action.payload };
    case FUEL_TANK_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case FUEL_TANK_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const fueltankUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case FUEL_TANK_UPDATE_REQUEST:
      return { loading: true };
    case FUEL_TANK_UPDATE_SUCCESS:
      return { loading: false, fueltankUpdate: action.payload };
    case FUEL_TANK_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case FUEL_TANK_UPDATE_RESET:
      return { fueltankCreate: {} };
    default:
      return state;
  }
};

export const fueltankDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case FUEL_TANK_DELETE_REQUEST:
      return { loading: true };
    case FUEL_TANK_DELETE_SUCCESS:
      return { loading: false, success: true };
    case FUEL_TANK_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//PRODUCT
export const productReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_REQUEST:
      return { loading: true, products: [] };
    case PRODUCT_SUCCESS:
      return { loading: false, products: action.payload };
    case PRODUCT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// export const dimentionsPermissionReducer = (
//   state = { departmentPermission: [] },
//   action
// ) => {
//   switch (action.type) {
//     case EMPLOYEES_PERMISSION_REQUEST:
//       return { loading: true, departmentPermission: [] };
//     case EMPLOYEES_PERMISSION_SUCCESS:
//       return { loading: false, departmentPermission: action.payload };
//     case EMPLOYEES_PERMISSION_FAIL:
//       return { loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };
