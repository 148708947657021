import {
  ITEM_REQUEST,
  ITEM_SUCCESS,
  ITEM_FAIL,
  ITEM_CREATE_REQUEST,
  ITEM_CREATE_SUCCESS,
  ITEM_CREATE_FAIL,
  ITEM_CREATE_RESET,
  ITEM_UPDATE_REQUEST,
  ITEM_UPDATE_SUCCESS,
  ITEM_UPDATE_FAIL,
  ITEM_UPDATE_RESET,
  ITEM_DELETE_REQUEST,
  ITEM_DELETE_SUCCESS,
  ITEM_DELETE_FAIL,
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAIL,
  ITEM_GROUP_REQUEST,
  ITEM_GROUP_SUCCESS,
  ITEM_GROUP_FAIL,
  UOM_REQUEST,
  UOM_SUCCESS,
  UOM_FAIL,
  ITEM_PERMISSION_REQUEST,
  ITEM_PERMISSION_SUCCESS,
  ITEM_PERMISSION_FAIL,
} from "../constants/itemConstant";

export const itemReducer = (state = { items: [] }, action) => {
  switch (action.type) {
    case ITEM_REQUEST:
      return { loading: true, items: [] };
    case ITEM_SUCCESS:
      return { loading: false, items: action.payload };
    case ITEM_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const itemCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ITEM_CREATE_REQUEST:
      return { loading: true };
    case ITEM_CREATE_SUCCESS:
      return { loading: false, itemCreate: action.payload };
    case ITEM_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ITEM_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const itemUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ITEM_UPDATE_REQUEST:
      return { loading: true };
    case ITEM_UPDATE_SUCCESS:
      return { loading: false, itemUpdate: action.payload };
    case ITEM_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ITEM_UPDATE_RESET:
      return { itemCreate: {} };
    default:
      return state;
  }
};

export const itemDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ITEM_DELETE_REQUEST:
      return { loading: true };
    case ITEM_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ITEM_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//USER
export const userReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_REQUEST:
      return { loading: true, users: [] };
    case USER_SUCCESS:
      return { loading: false, users: action.payload };
    case USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//Groups

export const groupReducer = (state = { groups: [] }, action) => {
  switch (action.type) {
    case ITEM_GROUP_REQUEST:
      return { loading: true, groups: [] };
    case ITEM_GROUP_SUCCESS:
      return { loading: false, groups: action.payload };
    case ITEM_GROUP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//uoms

export const uomReducer = (state = { uoms: [] }, action) => {
  switch (action.type) {
    case UOM_REQUEST:
      return { loading: true, uoms: [] };
    case UOM_SUCCESS:
      return { loading: false, uoms: action.payload };
    case UOM_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// export const productPermissionReducer = (
//   state = { departmentPermission: [] },
//   action
// ) => {
//   switch (action.type) {
//     case PRODUCT_PERMISSION_REQUEST:
//       return { loading: true, departmentPermission: [] };
//     case PRODUCT_PERMISSION_SUCCESS:
//       return { loading: false, departmentPermission: action.payload };
//     case PRODUCT_PERMISSION_FAIL:
//       return { loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };
