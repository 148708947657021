import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const { item, value, handleInputChange, error, helperText } = props;

  console.log("edit data", item);

  // const handleChange = (event) => {
  //   setRoleStatus(event.target.value);
  // };

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="shift"
              variant="outlined"
              required
              fullWidth
              id="shift"
              label="Shift"
              autoFocus
              size="small"
              defaultValue={item ? item.shift : null}
              error={error.shift}
              helperText={helperText.shift}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="alias"
              variant="outlined"
              fullWidth
              id="alias"
              label="Alias"
              autoFocus
              size="small"
              defaultValue={item ? item.alias : null}
              value={value.alias}
              error={error.alias}
              helperText={helperText.alias}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="in_hour"
              variant="outlined"
              fullWidth
              id="in_hour"
              label="In Hour"
              autoFocus
              size="small"
              defaultValue={item ? item.in_hour : null}
              value={value.in_hour}
              error={error.in_hour}
              helperText={helperText.in_hour}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="in_min"
              variant="outlined"
              fullWidth
              id="in_min"
              label="In Min"
              autoFocus
              size="small"
              defaultValue={item ? item.in_min : null}
              value={value.in_min}
              error={error.in_min}
              helperText={helperText.in_min}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="out_hour"
              variant="outlined"
              fullWidth
              id="out_hour"
              label="Out Hour"
              autoFocus
              size="small"
              defaultValue={item ? item.out_hour : null}
              value={value.out_hour}
              error={error.out_hour}
              helperText={helperText.out_hour}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="out_min"
              variant="outlined"
              fullWidth
              id="out_min"
              label="Out Min"
              autoFocus
              size="small"
              defaultValue={item ? item.out_min : null}
              value={value.out_min}
              error={error.out_min}
              helperText={helperText.out_min}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="print_serial"
              variant="outlined"
              fullWidth
              id="print_serial"
              label="Print Serial"
              autoFocus
              size="small"
              defaultValue={item ? item.print_serial : null}
              value={value.print_serial}
              error={error.print_serial}
              helperText={helperText.print_serial}
              onChange={handleInputChange}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              name="print_serial"
              variant="outlined"
              fullWidth
              id="print_serial"
              label="Print Serial"
              autoFocus
              size="small"
              defaultValue={item ? item.print_serial : null}
              // onChange={(e) => setEmailAddress(e.target.value)}
              value={value.print_serial}
              error={error.print_serial}
              helperText={helperText.print_serial}
              onChange={handleInputChange}
            />
          </Grid> */}
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
