import React, { useState, useEffect } from "react";

export function CreateValidation(validateOnChange = false, validate) {
  const [values, setValues] = useState({
    company_name: "",
    first_name: "",
    last_name: "",
    address: "",
    phone_no: "",
    email_address: "",
    password: "",
    logo: "",
    registration_date: "",
    renewal_date: "",
    status: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  console.log("bbb", values);

  const resetForm = () => {
    setValues(values);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}

export function EditValidation(item, validateOnChange = false, validate) {
  const [values, setValues] = useState({
    company_name: item ? item.company_name : "",
    first_name: item ? item.first_name : "",
    last_name: item ? item.last_name : "",
    phone_no: item ? item.phone_no : "",
    email_address: item ? item.email_address : "",
    address: item ? item.address : "",
    logo: item ? item.logo : "",
    registration_date: item ? item.registration_date : "",
    renewal_date: item ? item.renewal_date : "",
    status: item ? item.status : "",
  });
  const [errors, setErrors] = useState({});

  console.log("val", values);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  useEffect(() => {
    setValues({
      company_name: item.company_name,
      first_name: item.first_name,
      last_name: item.last_name,
      phone_no: item.phone_no,
      email_address: item.email_address,
      address: item.address,
      logo: item.logo,
      registration_date: item.registration_date,
      renewal_date: item.renewal_date,
      status: item.status,
    });
  }, [item]);

  console.log("bbb", values);

  const resetForm = () => {
    setValues(values);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}
