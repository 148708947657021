import {
  SHIFT_REQUEST,
  SHIFT_SUCCESS,
  SHIFT_FAIL,
  SHIFT_CREATE_REQUEST,
  SHIFT_CREATE_SUCCESS,
  SHIFT_CREATE_FAIL,
  SHIFT_CREATE_RESET,
  SHIFT_UPDATE_REQUEST,
  SHIFT_UPDATE_SUCCESS,
  SHIFT_UPDATE_FAIL,
  SHIFT_UPDATE_RESET,
  SHIFT_DELETE_REQUEST,
  SHIFT_DELETE_SUCCESS,
  SHIFT_DELETE_FAIL,
  SHIFT_PERMISSION_REQUEST,
  SHIFT_PERMISSION_SUCCESS,
  SHIFT_PERMISSION_FAIL,
} from "../constants/shiftConstant";

export const shiftReducer = (state = { shifts: [] }, action) => {
  switch (action.type) {
    case SHIFT_REQUEST:
      return { loading: true, shifts: [] };
    case SHIFT_SUCCESS:
      return { loading: false, shifts: action.payload };
    case SHIFT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const shiftCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SHIFT_CREATE_REQUEST:
      return { loading: true };
    case SHIFT_CREATE_SUCCESS:
      return { loading: false, shiftCreate: action.payload };
    case SHIFT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case SHIFT_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const shiftUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case SHIFT_UPDATE_REQUEST:
      return { loading: true };
    case SHIFT_UPDATE_SUCCESS:
      return { loading: false, shiftUpdate: action.payload };
    case SHIFT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case SHIFT_UPDATE_RESET:
      return { shiftCreate: {} };
    default:
      return state;
  }
};

export const shiftDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SHIFT_DELETE_REQUEST:
      return { loading: true };
    case SHIFT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case SHIFT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// export const productPermissionReducer = (
//   state = { departmentPermission: [] },
//   action
// ) => {
//   switch (action.type) {
//     case SHIFT_PERMISSION_REQUEST:
//       return { loading: true, departmentPermission: [] };
//     case SHIFT_PERMISSION_SUCCESS:
//       return { loading: false, departmentPermission: action.payload };
//     case SHIFT_PERMISSION_FAIL:
//       return { loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };
