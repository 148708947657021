import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "./Table";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { userRoleList } from "../../../redux/actions/userRoleActions";
import { permissionAction } from "../../../redux/actions/permissionActions";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Body = (props) => {
  const { userData } = props;
  const dispatch = useDispatch();
  const [app_menu_id, setAppMenuId] = useState(9);

  const role = useSelector((state) => state.role);

  const { roles } = role;

  const permission = useSelector((state) => state.permission);

  const { permissions } = permission;

  console.log("permission - xyz", permissions);
  console.log(userData);

  useEffect(() => {
    dispatch(userRoleList());
    dispatch(permissionAction(userData, app_menu_id));
  }, [dispatch, userData]);

  const classes = useStyles();

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Icon style={{ fontSize: 25, marginTop: 18 }}>settings</Icon>
        <h2 style={{ marginLeft: 5 }}>User Roles</h2>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: -30,
          fontSize: 11,
          marginLeft: 25,
        }}
      >
        <p>Settings</p>
        <Icon style={{ fontSize: 14, marginTop: 12 }}>navigate_next</Icon>
        <p> User Roles</p>
      </div>

      <div style={{ marginTop: 20 }}>
        <Table roles={roles} permissions={permissions} />
      </div>
    </div>
  );
};

export default Body;
