import axios from "axios";
import {
  MODULE_REQUEST,
  MODULE_SUCCESS,
  MODULE_FAIL,
  MODULE_GLOBAL_REQUEST,
  MODULE_GLOBAL_SUCCESS,
  MODULE_GLOBAL_FAIL,
} from "../constants/moduleConstants";

export const moduleList = () => async (dispatch) => {
  try {
    dispatch({
      type: MODULE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(
      "http://stockmanagement.clonestudiobd.com/api/appmenus",
      config
    );

    console.log("module", data);

    dispatch({
      type: MODULE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MODULE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const moduleGlobalAction = () => async (dispatch) => {
  try {
    dispatch({
      type: MODULE_GLOBAL_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(
      "https://stockmanagement.clonestudiobd.com/api/appmenus",
      config
    );

    console.log("module", data);

    dispatch({
      type: MODULE_GLOBAL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MODULE_GLOBAL_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
