export const ITEM_REQUEST = "ITEM_REQUEST";
export const ITEM_SUCCESS = "ITEM_SUCCESS";
export const ITEM_FAIL = "ITEM_GROUP_FAIL";

export const ITEM_CREATE_REQUEST = "ITEM_CREATE_REQUEST";
export const ITEM_CREATE_SUCCESS = "ITEM_CREATE_SUCCESS";
export const ITEM_CREATE_FAIL = "ITEM_CREATE_FAIL";
export const ITEM_CREATE_RESET = "ITEM_CREATE_RESET";

export const ITEM_UPDATE_REQUEST = "ITEM_UPDATE_REQUEST";
export const ITEM_UPDATE_SUCCESS = "ITEM_UPDATE_SUCCESS";
export const ITEM_UPDATE_FAIL = "ITEM_UPDATE_FAIL";
export const ITEM_UPDATE_RESET = "ITEM_UPDATE_RESET";

export const ITEM_DELETE_REQUEST = "ITEM_DELETE_REQUEST";
export const ITEM_DELETE_SUCCESS = "ITEM_DELETE_SUCCESS";
export const ITEM_DELETE_FAIL = "ITEM_DELETE_FAIL";

export const ITEM_PERMISSION_REQUEST = "ITEM_PERMISSION_REQUEST";
export const ITEM_PERMISSION_SUCCESS = "ITEM_PERMISSION_SUCCESS";
export const ITEM_PERMISSION_FAIL = "ITEM_PERMISSION_FAIL";

//USER
export const USER_REQUEST = "USER_REQUEST";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAIL = "USER_FAIL";

//Groups

export const ITEM_GROUP_REQUEST = "ITEM_GROUP_REQUEST";
export const ITEM_GROUP_SUCCESS = "ITEM_GROUP_SUCCESS";
export const ITEM_GROUP_FAIL = "ITEM_GROUP_FAIL";

//uoms
export const UOM_REQUEST = "UOM_REQUEST";
export const UOM_SUCCESS = "UOM_SUCCESS";
export const UOM_FAIL = "UOM_FAIL";
