import React, { useState, useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";

import AppMenuItem from "./ModuleItems";
import { moduleList } from "../../../redux/actions/moduleActions";

const Module = ({ isSidebarOpened }) => {
  const classes = useStyles();
  const [data, setData] = useState("");
  const [value, setValue] = useState({
    subModule: [],
  });

  const dispatch = useDispatch();
  const module = useSelector((state) => state.module);

  const { modules } = module;

  console.log("234", modules);

  useEffect(() => {
    dispatch(moduleList());
  }, [dispatch]);

  const tree = (function (modules, root) {
    var o = {};
    modules &&
      modules.forEach(function (a) {
        a.children = o[a.id] && o[a.id].children;
        o[a.id] = a;
        o[a.parent_menu] = o[a.parent_menu] || {};
        o[a.parent_menu].children = o[a.parent_menu].children || [];
        o[a.parent_menu].children.push(a);
      });
    return o[root];
  })(modules, 0);

  console.log("tree", JSON.stringify(tree));
  return (
    <List component="nav" className={classes.appMenu} disablePadding>
      {/* <AppMenuItem {...appMenuItems[0]} /> */}
      <div style={{ marginTop: 10 }}>
        {tree
          ? tree.children.map((item, index) => (
              <div style={{ marginRight: 10, marginLeft: 9, marginTop: 2 }}>
                <AppMenuItem
                  {...item}
                  key={index}
                  isSidebarOpened={isSidebarOpened}
                />
                <Divider />
              </div>
            ))
          : null}
      </div>
    </List>
  );
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) =>
  createStyles({
    appMenu: {
      width: "100%",
      height: 1500,
      backgroundColor: "#203946",
      color: "white",
    },
  })
);

export default Module;
