export const DISPENSERS_REQUEST = "DISPENSERS_REQUEST";
export const DISPENSERS_SUCCESS = "DISPENSERS_SUCCESS";
export const DISPENSERS_FAIL = "DISPENSERS_FAIL";


export const DISPENSERS_CREATE_REQUEST = "DISPENSERS_CREATE_REQUEST";
export const DISPENSERS_CREATE_SUCCESS = "DISPENSERS_CREATE_SUCCESS";
export const DISPENSERS_CREATE_FAIL = "DISPENSERS_CREATE_FAIL";
export const DISPENSERS_CREATE_RESET = "DISPENSERS_CREATE_RESET";

export const DISPENSERS_UPDATE_REQUEST = "DISPENSERS_UPDATE_REQUEST";
export const DISPENSERS_UPDATE_SUCCESS = "DISPENSERS_UPDATE_SUCCESS";
export const DISPENSERS_UPDATE_FAIL = "DISPENSERS_UPDATE_FAIL";
export const DISPENSERS_UPDATE_RESET = "DISPENSERS_UPDATE_RESET";

export const DISPENSERS_DELETE_REQUEST = "DISPENSERS_DELETE_REQUEST";
export const DISPENSERS_DELETE_SUCCESS = "DISPENSERS_DELETE_SUCCESS";
export const DISPENSERS_DELETE_FAIL = "DISPENSERS_DELETE_FAIL";

export const DISPENSERS_PERMISSION_REQUEST = "DISPENSERS_PERMISSION_REQUEST";
export const DISPENSERS_PERMISSION_SUCCESS = "DISPENSERS_PERMISSION_SUCCESS";
export const DISPENSERS_PERMISSION_FAIL = "DISPENSERS_PERMISSION_FAIL";

//PRODUCTS
export const PRODUCT_REQUEST = "PRODUCT_REQUEST";
export const PRODUCT_SUCCESS = "PRODUCT_SUCCESS";
export const PRODUCT_FAIL = "PRODUCT_FAIL";
