import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    value,
    handleInputChange,
    error,
    helperText,
    setRoleId,
    setDesignationId,
    setShiftId,
    employees,
    designations,
    shifts,
    setProductId,
    item,
  } = props;

  console.log("edit data", item);

  // const handleChange = (event) => {
  //   setRoleStatus(event.target.value);
  // };

  var designationName = item
    ? designations
        .filter((o1) => {
          return o1.id === item.designation_id;
        })
        .map((item) => item.designation)
    : null;

  var shiftName = item
    ? shifts
        .filter((o1) => {
          return o1.id === item.shift_id;
        })
        .map((item) => item.shift)
    : null;

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              name="First_Name"
              variant="outlined"
              required
              fullWidth
              id="First_Name"
              label="First Name"
              autoFocus
              size="small"
              defaultValue={item ? item.First_Name : null}
              error={error.First_Name}
              helperText={helperText.First_Name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="last_name"
              variant="outlined"
              required
              fullWidth
              id="last_name"
              label="Last Name"
              autoFocus
              size="small"
              defaultValue={item ? item.last_name : null}
              error={error.last_name}
              helperText={helperText.last_name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="fathers_name"
              variant="outlined"
              required
              fullWidth
              id="fathers_name"
              label="Fathers Name"
              autoFocus
              size="small"
              defaultValue={item ? item.fathers_name : null}
              error={error.fathers_name}
              helperText={helperText.fathers_name}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="monther_name"
              variant="outlined"
              required
              fullWidth
              id="monther_name"
              label="Monther Name"
              autoFocus
              size="small"
              defaultValue={item ? item.monther_name : null}
              error={error.monther_name}
              helperText={helperText.monther_name}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                Designation *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setDesignationId(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? designationName : "None"}</option>
                {designations
                  ? designations.map((item) => (
                      <option value={item.id}>{item.designation}</option>
                    ))
                  : null}
              </NativeSelect>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                Shift *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setShiftId(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? shiftName : "None"}</option>
                {shifts
                  ? shifts.map((item) => (
                      <option value={item.id}>{item.shift}</option>
                    ))
                  : null}
              </NativeSelect>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="birth_date"
              variant="outlined"
              required
              fullWidth
              id="birth_date"
              label="birth_date"
              type="date"
              autoFocus
              size="small"
              defaultValue={item ? item.birth_date : null}
              error={error.birth_date}
              helperText={helperText.birth_date}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="join_date"
              variant="outlined"
              required
              fullWidth
              id="join_date"
              label="Join Date"
              type="date"
              autoFocus
              size="small"
              defaultValue={item ? item.join_date : null}
              error={error.join_date}
              helperText={helperText.join_date}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="phone_no"
              variant="outlined"
              required
              fullWidth
              id="phone_no"
              label="Phone No"
              autoFocus
              size="small"
              defaultValue={item ? item.phone_no : null}
              error={error.phone_no}
              helperText={helperText.phone_no}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="email_address"
              variant="outlined"
              required
              fullWidth
              id="email_address"
              label="Email Address"
              autoFocus
              size="small"
              defaultValue={item ? item.email_address : null}
              error={error.email_address}
              helperText={helperText.email_address}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="gross_salary"
              variant="outlined"
              required
              fullWidth
              id="gross_salary"
              label="Gross Salary"
              autoFocus
              size="small"
              defaultValue={item ? item.gross_salary : null}
              error={error.gross_salary}
              helperText={helperText.gross_salary}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
