import {
  ACCOUNT_REQUEST,
  ACCOUNT_SUCCESS,
  ACCOUNT_FAIL,
  ACCOUNT_CREATE_REQUEST,
  ACCOUNT_CREATE_SUCCESS,
  ACCOUNT_CREATE_FAIL,
  ACCOUNT_CREATE_RESET,
  ACCOUNT_UPDATE_REQUEST,
  ACCOUNT_UPDATE_SUCCESS,
  ACCOUNT_UPDATE_FAIL,
  ACCOUNT_UPDATE_RESET,
  ACCOUNT_DELETE_REQUEST,
  ACCOUNT_DELETE_SUCCESS,
  ACCOUNT_DELETE_FAIL,
  ACCOUNT_PERMISSION_REQUEST,
  ACCOUNT_PERMISSION_SUCCESS,
  ACCOUNT_PERMISSION_FAIL,
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAIL,
} from "../constants/accountConstant";

export const accountReducer = (state = { accounts: [] }, action) => {
  switch (action.type) {
    case ACCOUNT_REQUEST:
      return { loading: true, accounts: [] };
    case ACCOUNT_SUCCESS:
      return { loading: false, accounts: action.payload };
    case ACCOUNT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const accountCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ACCOUNT_CREATE_REQUEST:
      return { loading: true };
    case ACCOUNT_CREATE_SUCCESS:
      return { loading: false, accountCreates: action.payload };
    case ACCOUNT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ACCOUNT_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const accountUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ACCOUNT_UPDATE_REQUEST:
      return { loading: true };
    case ACCOUNT_UPDATE_SUCCESS:
      return { loading: false, accountUpdates: action.payload };
    case ACCOUNT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ACCOUNT_UPDATE_RESET:
      return { accountCreates: {} };
    default:
      return state;
  }
};

export const accountDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ACCOUNT_DELETE_REQUEST:
      return { loading: true };
    case ACCOUNT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ACCOUNT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// export const organizationPermissionReducer = (
//   state = { organizationPermission: [] },
//   action
// ) => {
//   switch (action.type) {
//     case ACCOUNT_PERMISSION_REQUEST:
//       return { loading: true, organizationPermission: [] };
//     case ACCOUNT_PERMISSION_SUCCESS:
//       return { loading: false, organizationPermission: action.payload };
//     case ACCOUNT_PERMISSION_FAIL:
//       return { loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };

//USER

export const userReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_REQUEST:
      return { loading: true, users: [] };
    case USER_SUCCESS:
      return { loading: false, users: action.payload };
    case USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
