import axios from "axios";
import {
  ITEM_GROUP_REQUEST,
  ITEM_GROUP_SUCCESS,
  ITEM_GROUP_FAIL,
  ITEM_GROUP_CREATE_REQUEST,
  ITEM_GROUP_CREATE_SUCCESS,
  ITEM_GROUP_CREATE_FAIL,
  ITEM_GROUP_CREATE_RESET,
  ITEM_GROUP_UPDATE_REQUEST,
  ITEM_GROUP_UPDATE_SUCCESS,
  ITEM_GROUP_UPDATE_FAIL,
  ITEM_GROUP_UPDATE_RESET,
  ITEM_GROUP_DELETE_REQUEST,
  ITEM_GROUP_DELETE_SUCCESS,
  ITEM_GROUP_DELETE_FAIL,
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAIL,
  ITEM_GROUP_PERMISSION_REQUEST,
  ITEM_GROUP_PERMISSION_SUCCESS,
  ITEM_GROUP_PERMISSION_FAIL,
} from "../constants/groupConstant";

export const groupList = () => async (dispatch) => {
  try {
    dispatch({
      type: ITEM_GROUP_REQUEST,
    });

    const { data } = await axios.get(
      "http://stockmanagement.clonestudiobd.com/api/groups"
    );

    dispatch({
      type: ITEM_GROUP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ITEM_GROUP_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const groupCreateAction =
  (user_id, group_name, created_by, modified_by) => async (dispatch) => {
    try {
      dispatch({
        type: ITEM_GROUP_CREATE_REQUEST,
      });

      const config = {
        headers: {
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        "http://stockmanagement.clonestudiobd.com/api/groups",
        {
          user_id,
          group_name,
          created_by,
          modified_by,
        },
        config
      );

      console.log("data4", user_id, group_name, created_by, modified_by);

      dispatch({
        type: ITEM_GROUP_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ITEM_GROUP_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const groupUpdateAction =
  (id, user_id, group_name, created_by, modified_by) => async (dispatch) => {
    try {
      dispatch({
        type: ITEM_GROUP_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.put(
        `http://stockmanagement.clonestudiobd.com/api/groups/${id}`,
        {
          user_id,
          group_name,
          created_by,
          modified_by,
        },
        config
      );

      console.log("update data", user_id, group_name, created_by, modified_by);

      dispatch({
        type: ITEM_GROUP_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ITEM_GROUP_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const groupDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ITEM_GROUP_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(
      `http://stockmanagement.clonestudiobd.com/api/groups/${id}`,
      config
    );

    dispatch({
      type: ITEM_GROUP_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: ITEM_GROUP_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

//USER

export const userList = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_REQUEST,
    });

    const { data } = await axios.get(
      "http://stockmanagement.clonestudiobd.com/api/users"
    );

    dispatch({
      type: USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// export const dimentionsPermissionAction =
//   (user_role_id, app_menu_id) => async (dispatch) => {
//     try {
//       dispatch({
//         type: PRODUCT_PERMISSION_REQUEST,
//       });

//       const config = {
//         headers: {
//           Accept: "application/json",
//         },
//       };

//       console.log("DataCheck", user_role_id, app_menu_id);

//       const { data } = await axios.post(
//         "http://bbox.clonestudiobd.com/api/userrolefunctions/permission",
//         {
//           user_role_id: user_role_id,
//           app_menu_id: app_menu_id,
//         },
//         config
//       );

//       console.log("DataP", user_role_id, app_menu_id);

//       dispatch({
//         type: PRODUCT_PERMISSION_SUCCESS,
//         payload: data,
//       });
//     } catch (error) {
//       dispatch({
//         type: PRODUCT_PERMISSION_FAIL,
//         payload:
//           error.response && error.response.data.error
//             ? error.response.data.error
//             : error.error,
//       });
//     }
//   };
