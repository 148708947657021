export const FUEL_TANK_REQUEST = "FUEL_TANK_REQUEST";
export const FUEL_TANK_SUCCESS = "FUEL_TANK_SUCCESS";
export const FUEL_TANK_FAIL = "FUEL_TANK_FAIL";

export const FUEL_TANK_CREATE_REQUEST = "FUEL_TANK_CREATE_REQUEST";
export const FUEL_TANK_CREATE_SUCCESS = "FUEL_TANK_CREATE_SUCCESS";
export const FUEL_TANK_CREATE_FAIL = "FUEL_TANK_CREATE_FAIL";
export const FUEL_TANK_CREATE_RESET = "FUEL_TANK_CREATE_RESET";

export const FUEL_TANK_UPDATE_REQUEST = "FUEL_TANK_UPDATE_REQUEST";
export const FUEL_TANK_UPDATE_SUCCESS = "FUEL_TANK_UPDATE_SUCCESS";
export const FUEL_TANK_UPDATE_FAIL = "FUEL_TANK_UPDATE_FAIL";
export const FUEL_TANK_UPDATE_RESET = "FUEL_TANK_UPDATE_RESET";

export const FUEL_TANK_DELETE_REQUEST = "FUEL_TANK_DELETE_REQUEST";
export const FUEL_TANK_DELETE_SUCCESS = "FUEL_TANK_DELETE_SUCCESS";
export const FUEL_TANK_DELETE_FAIL = "FUEL_TANK_DELETE_FAIL";

export const FUEL_TANK_PERMISSION_REQUEST = "FUEL_TANK_PERMISSION_REQUEST";
export const FUEL_TANK_PERMISSION_SUCCESS = "FUEL_TANK_PERMISSION_SUCCESS";
export const FUEL_TANK_PERMISSION_FAIL = "FUEL_TANK_PERMISSION_FAIL";

//PRODUCTS
export const PRODUCT_REQUEST = "PRODUCT_REQUEST";
export const PRODUCT_SUCCESS = "PRODUCT_SUCCESS";
export const PRODUCT_FAIL = "PRODUCT_FAIL";
