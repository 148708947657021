export const UOM_REQUEST = "UOM_REQUEST";
export const UOM_SUCCESS = "UOM_SUCCESS";
export const UOM_FAIL = "UOM_FAIL";

export const UOM_CREATE_REQUEST = "UOM_CREATE_REQUEST";
export const UOM_CREATE_SUCCESS = "UOM_CREATE_SUCCESS";
export const UOM_CREATE_FAIL = "UOM_CREATE_FAIL";
export const UOM_CREATE_RESET = "UOM_CREATE_RESET";

export const UOM_UPDATE_REQUEST = "UOM_UPDATE_REQUEST";
export const UOM_UPDATE_SUCCESS = "UOM_UPDATE_SUCCESS";
export const UOM_UPDATE_FAIL = "UOM_UPDATE_FAIL";
export const UOM_UPDATE_RESET = "UOM_UPDATE_RESET";

export const UOM_DELETE_REQUEST = "UOM_DELETE_REQUEST";
export const UOM_DELETE_SUCCESS = "UOM_DELETE_SUCCESS";
export const UOM_DELETE_FAIL = "UOM_DELETE_FAIL";

export const UOM_PERMISSION_REQUEST = "UOM_PERMISSION_REQUEST";
export const UOM_PERMISSION_SUCCESS = "UOM_PERMISSION_SUCCESS";
export const UOM_PERMISSION_FAIL = "UOM_PERMISSION_FAIL";

//USER
export const USER_REQUEST = "USER_REQUEST";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAIL = "USER_FAIL";
