import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    item,
    value,
    handleInputChange,
    error,
    helperText,
    users,
    groups,
    uoms,
    setUserId,
    setItemGroupId,
    setUomId,
  } = props;

  var companyName = item
    ? users
        .filter((o1) => {
          return o1.id === item.user_id;
        })
        .map((item) => item.company_name)
    : null;

  var groupName = item
    ? groups
        .filter((o1) => {
          return o1.id === item.item_group_id;
        })
        .map((item) => item.group_name)
    : null;

  var uomName = item
    ? uoms
        .filter((o1) => {
          return o1.id === item.uom_id;
        })
        .map((item) => item.uom)
    : null;

  console.log("edit data", item);

  // const handleChange = (event) => {
  //   setRoleStatus(event.target.value);
  // };

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                Company Name *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setUserId(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? companyName : "None"}</option>
                {users
                  ? users.map((item) => (
                      <option value={item.id}>{item.company_name}</option>
                    ))
                  : null}
              </NativeSelect>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="item_name"
              variant="outlined"
              fullWidth
              id="item_name"
              label="Item Name"
              autoFocus
              size="small"
              defaultValue={item ? item.item_name : null}
              // value={value.item_name}
              error={error.item_name}
              helperText={helperText.item_name}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                Group Name *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setItemGroupId(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? groupName : "None"}</option>
                {groups
                  ? groups.map((item) => (
                      <option value={item.id}>{item.group_name}</option>
                    ))
                  : null}
              </NativeSelect>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                UOM *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setUomId(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? uomName : "None"}</option>
                {uoms
                  ? uoms.map((item) => (
                      <option value={item.id}>{item.uom}</option>
                    ))
                  : null}
              </NativeSelect>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="purchase_value"
              variant="outlined"
              fullWidth
              id="purchase_value"
              label="Purchase Value"
              autoFocus
              size="small"
              defaultValue={item ? item.purchase_value : null}
              // value={value.purchase_value}
              error={error.purchase_value}
              helperText={helperText.purchase_value}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="sale_value"
              variant="outlined"
              fullWidth
              id="sale_value"
              label="Sale Value"
              autoFocus
              size="small"
              defaultValue={item ? item.sale_value : null}
              // value={value.sale_value}
              error={error.sale_value}
              helperText={helperText.sale_value}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="min_stock"
              variant="outlined"
              fullWidth
              id="min_stock"
              label="Min Stock"
              autoFocus
              size="small"
              defaultValue={item ? item.min_stock : null}
              // value={value.min_stock}
              error={error.min_stock}
              helperText={helperText.min_stock}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="max_stock"
              variant="outlined"
              fullWidth
              id="max_stock"
              label="Max Stock"
              autoFocus
              size="small"
              defaultValue={item ? item.max_stock : null}
              // value={value.max_stock}
              error={error.max_stock}
              helperText={helperText.max_stock}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="discount_Per"
              variant="outlined"
              fullWidth
              id="discount_Per"
              label="Discount Per"
              autoFocus
              size="small"
              defaultValue={item ? item.discount_Per : null}
              // value={value.discount_Per}
              error={error.discount_Per}
              helperText={helperText.discount_Per}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="vat_per"
              variant="outlined"
              fullWidth
              id="vat_per"
              label="Vat Per"
              autoFocus
              size="small"
              defaultValue={item ? item.vat_per : null}
              // value={value.vat_per}
              error={error.vat_per}
              helperText={helperText.vat_per}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="opening_stock"
              variant="outlined"
              fullWidth
              id="opening_stock"
              label="Opening Stock"
              autoFocus
              size="small"
              defaultValue={item ? item.opening_stock : null}
              // value={value.opening_stock}
              error={error.opening_stock}
              helperText={helperText.opening_stock}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
