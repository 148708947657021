import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  userLoginReducer,
  userListReducer,
  userCreateReducer,
  userUpdateReducer,
  userDeleteReducer,
} from "./components/redux/reducers/userReducers";
import {
  moduleReducerGlobal,
  moduleReducerSidebar,
} from "./components/redux/reducers/moduleReducers";
import {
  userRoleReducer,
  userRoleCreateReducer,
  userRoleUpdateReducer,
  userRoleDeleteReducer,
  roleFunctionReducer,
  roleFunctionCreateReducer,
  roleFunctionUpdateReducer,
  roleFunctionDeleteReducer,
} from "./components/redux/reducers/userRoleReducers";

import {
  productReducer,
  productCreateReducer,
  productUpdateReducer,
  productDeleteReducer,
  // dimentionsPermissionReducer,
} from "./components/redux/reducers/productReducer";

import {
  fueltankReducer,
  fueltankCreateReducer,
  fueltankUpdateReducer,
  fueltankDeleteReducer,
  // productReducer,
  // dimentionsPermissionReducer,
} from "./components/redux/reducers/fueltankReducer";

import {
  designationReducer,
  designationCreateReducer,
  designationUpdateReducer,
  designationDeleteReducer,
} from "./components/redux/reducers/reducers";

import {
  shiftReducer,
  shiftCreateReducer,
  shiftUpdateReducer,
  shiftDeleteReducer,
  // dimentionsPermissionReducer,
} from "./components/redux/reducers/shiftReducer";

import {
  measurementReducer,
  measurementCreateReducer,
  measurementUpdateReducer,
  measurementDeleteReducer,
} from "./components/redux/reducers/measuringReducers";

import {
  employeeReducer,
  employeeCreateReducer,
  employeeUpdateReducer,
  employeeDeleteReducer,
  // designationReducer,
  // shiftReducer,
  // productReducer,
  // dimentionsPermissionReducer,
} from "./components/redux/reducers/employeeReducer";

import {
  dispensersReducer,
  dispensersCreateReducer,
  dispensersUpdateReducer,
  dispensersDeleteReducer,
  // productReducer,
  // dimentionsPermissionReducer,
} from "./components/redux/reducers/dispenserReducer";

import {
  uomReducer,
  uomCreateReducer,
  uomUpdateReducer,
  uomDeleteReducer,
  // userReducer,
  // dimentionsPermissionReducer,
} from "./components/redux/reducers/uomReducer";

import {
  groupReducer,
  groupCreateReducer,
  groupUpdateReducer,
  groupDeleteReducer,
  // userReducer,
  // dimentionsPermissionReducer,
} from "./components/redux/reducers/groupReducer";

import {
  itemReducer,
  itemCreateReducer,
  itemUpdateReducer,
  itemDeleteReducer,
  // userReducer,
  // groupReducer,
  // dimentionsPermissionReducer,
} from "./components/redux/reducers/itemReducer";

import {
  accountReducer,
  accountCreateReducer,
  accountUpdateReducer,
  accountDeleteReducer,
  // userReducer,
  // organizationPermissionReducer,
} from "./components/redux/reducers/accountReducer";

import { permissionReducer } from "./components/redux/reducers/permissionReducers";

import { toggleSidebarReducer } from "./components/redux/reducers/globalStateReducers";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  module: moduleReducerSidebar,
  moduleGlobal: moduleReducerGlobal,
  user: userListReducer,
  userCreate: userCreateReducer,
  userUpdate: userUpdateReducer,
  userDelete: userDeleteReducer,
  leftSidebar: toggleSidebarReducer,
  permission: permissionReducer,
  role: userRoleReducer,
  roleCreate: userRoleCreateReducer,
  roleUpdate: userRoleUpdateReducer,
  roleDelete: userRoleDeleteReducer,
  roleFunction: roleFunctionReducer,
  roleFunctionCreate: roleFunctionCreateReducer,
  roleFunctionUpdate: roleFunctionUpdateReducer,
  roleFunctionDelete: roleFunctionDeleteReducer,

  product: productReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productDelete: productDeleteReducer,

  fueltank: fueltankReducer,
  fueltankCreate: fueltankCreateReducer,
  fueltankUpdate: fueltankUpdateReducer,
  fueltankDelete: fueltankDeleteReducer,
  product: productReducer,

  shift: shiftReducer,
  shiftCreate: shiftCreateReducer,
  shiftUpdate: shiftUpdateReducer,
  shiftDelete: shiftDeleteReducer,

  designation: designationReducer,
  designationCreate: designationCreateReducer,
  designationUpdate: designationUpdateReducer,
  designationDelete: designationDeleteReducer,

  measurementData: measurementReducer,
  measurementCreate: measurementCreateReducer,
  measurementUpdate: measurementUpdateReducer,
  measurementDelete: measurementDeleteReducer,

  employee: employeeReducer,
  employeeCreate: employeeCreateReducer,
  employeeUpdate: employeeUpdateReducer,
  employeeDelete: employeeDeleteReducer,
  // designation: designationReducer,
  // shift: shiftReducer,

  dispenser: dispensersReducer,
  dispensersCreate: dispensersCreateReducer,
  dispensersUpdate: dispensersUpdateReducer,
  dispensersDelete: dispensersDeleteReducer,
  // product: productReducer,

  uom: uomReducer,
  // user: userReducer,
  uomCreate: uomCreateReducer,
  uomUpdate: uomUpdateReducer,
  uomDelete: uomDeleteReducer,

  group: groupReducer,
  // user: userReducer,
  groupCreate: groupCreateReducer,
  groupUpdate: groupUpdateReducer,
  groupDelete: groupDeleteReducer,

  // group: groupReducer,
  // user: userReducer,
  item: itemReducer,
  itemCreate: itemCreateReducer,
  itemUpdate: itemUpdateReducer,
  itemDelete: itemDeleteReducer,

  account: accountReducer,
  // user: userReducer,
  accountCreate: accountCreateReducer,
  accountUpdate: accountUpdateReducer,
  accountDelete: accountDeleteReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
