import {
  UOM_REQUEST,
  UOM_SUCCESS,
  UOM_FAIL,
  UOM_CREATE_REQUEST,
  UOM_CREATE_SUCCESS,
  UOM_CREATE_FAIL,
  UOM_CREATE_RESET,
  UOM_UPDATE_REQUEST,
  UOM_UPDATE_SUCCESS,
  UOM_UPDATE_FAIL,
  UOM_UPDATE_RESET,
  UOM_DELETE_REQUEST,
  UOM_DELETE_SUCCESS,
  UOM_DELETE_FAIL,
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAIL,
  UOM_PERMISSION_REQUEST,
  UOM_PERMISSION_SUCCESS,
  UOM_PERMISSION_FAIL,
} from "../constants/uomConstant";

export const uomReducer = (state = { uoms: [] }, action) => {
  switch (action.type) {
    case UOM_REQUEST:
      return { loading: true, uoms: [] };
    case UOM_SUCCESS:
      return { loading: false, uoms: action.payload };
    case UOM_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const uomCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case UOM_CREATE_REQUEST:
      return { loading: true };
    case UOM_CREATE_SUCCESS:
      return { loading: false, uomCreate: action.payload };
    case UOM_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case UOM_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const uomUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case UOM_UPDATE_REQUEST:
      return { loading: true };
    case UOM_UPDATE_SUCCESS:
      return { loading: false, uomUpdate: action.payload };
    case UOM_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case UOM_UPDATE_RESET:
      return { uomCreate: {} };
    default:
      return state;
  }
};

export const uomDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case UOM_DELETE_REQUEST:
      return { loading: true };
    case UOM_DELETE_SUCCESS:
      return { loading: false, success: true };
    case UOM_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//USER
export const userReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_REQUEST:
      return { loading: true, users: [] };
    case USER_SUCCESS:
      return { loading: false, users: action.payload };
    case USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// export const productPermissionReducer = (
//   state = { departmentPermission: [] },
//   action
// ) => {
//   switch (action.type) {
//     case PRODUCT_PERMISSION_REQUEST:
//       return { loading: true, departmentPermission: [] };
//     case PRODUCT_PERMISSION_SUCCESS:
//       return { loading: false, departmentPermission: action.payload };
//     case PRODUCT_PERMISSION_FAIL:
//       return { loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };
