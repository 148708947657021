import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "./Table";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  itemList,
  groupList,
  userList,
  uomList,
  // departmentPermissionAction,
} from "../../../redux/actions/itemAction";

import SettingsIcon from "@material-ui/icons/Settings";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Body = (props) => {
  const { userData } = props;
  const dispatch = useDispatch();
  //   const [app_menu_id, setAppMenuId] = useState(32);

  const item = useSelector((state) => state.item);
  const { items } = item;

  const group = useSelector((state) => state.group);
  const { groups } = group;

  const user = useSelector((state) => state.user);
  const { users } = user;

  const uom = useSelector((state) => state.uom);
  const { uoms } = uom;

  //   const permission = useSelector((state) => state.permission);
  //   const departmentPermissions = useSelector(
  //     (state) => state.departmentPermission
  //   );
  //   const { departmentPermission } = departmentPermissions;

  //   console.log("permission - xyz", departmentPermissions);
  console.log(userData);

  useEffect(() => {
    dispatch(itemList());
    dispatch(groupList());
    dispatch(userList());
    dispatch(uomList());
    // dispatch(departmentPermissionAction(userData, app_menu_id));
  }, [dispatch, userData]);

  const classes = useStyles();

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Icon style={{ fontSize: 25, marginTop: 18 }}>category</Icon>
        <h2 style={{ marginLeft: 5 }}>Item</h2>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: -30,
          fontSize: 11,
          marginLeft: 25,
        }}
      >
        <p>Settings</p>
        {/* <Icon style={{ fontSize: 14, marginTop: 12 }}>navigate_next</Icon> */}
        <NavigateNextIcon style={{ fontSize: 14, marginTop: 12 }}>
          navigate_next
        </NavigateNextIcon>
        <p>Item</p>
      </div>

      <div style={{ marginTop: 20 }}>
        <Table items={items} groups={groups} users={users} uoms={uoms} />
        {/* <Table departments={departments} permissions={permissions} /> */}
      </div>
    </div>
  );
};

export default Body;
