import axios from "axios";
import {
  DISPENSERS_REQUEST,
  DISPENSERS_SUCCESS,
  DISPENSERS_FAIL,
  DISPENSERS_CREATE_REQUEST,
  DISPENSERS_CREATE_SUCCESS,
  DISPENSERS_CREATE_FAIL,
  DISPENSERS_CREATE_RESET,
  DISPENSERS_UPDATE_REQUEST,
  DISPENSERS_UPDATE_SUCCESS,
  DISPENSERS_UPDATE_FAIL,
  DISPENSERS_UPDATE_RESET,
  DISPENSERS_DELETE_REQUEST,
  DISPENSERS_DELETE_SUCCESS,
  DISPENSERS_DELETE_FAIL,
  DISPENSERS_PERMISSION_REQUEST,
  DISPENSERS_PERMISSION_SUCCESS,
  DISPENSERS_PERMISSION_FAIL,
  PRODUCT_REQUEST,
  PRODUCT_SUCCESS,
  PRODUCT_FAIL,
} from "../constants/dispenserConstant";

export const dispensersList = () => async (dispatch) => {
  try {
    dispatch({
      type: DISPENSERS_REQUEST,
    });

    const { data } = await axios.get(
      "http://auto.clonestudiobd.com/api/dispensers"
    );

    dispatch({
      type: DISPENSERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DISPENSERS_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const dispensersCreateAction =
  (
    dispenser_name,
    alias,

    product_id,
    created_by,
    modified_by
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: DISPENSERS_CREATE_REQUEST,
      });

      const config = {
        headers: {
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        "http://auto.clonestudiobd.com/api/dispensers",
        {
          dispenser_name,
          alias,

          product_id,
          created_by,
          modified_by,
        },
        config
      );

      console.log(
        "data4",

        dispenser_name,
        alias,

        product_id,
        created_by,
        modified_by
      );

      dispatch({
        type: DISPENSERS_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DISPENSERS_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const dispensersUpdateAction =
  (
    id,
    dispenser_name,
    alias,

    product_id,
    created_by,
    modified_by
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: DISPENSERS_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        `http://auto.clonestudiobd.com/api/dispensers/${id}`,
        {
          dispenser_name,
          alias,

          product_id,
          created_by,
          modified_by,
        },
        config
      );

      console.log(
        "update data",

        dispenser_name,
        alias,

        product_id,
        created_by,
        modified_by
      );

      dispatch({
        type: DISPENSERS_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DISPENSERS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const dispensersDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DISPENSERS_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(
      `http://auto.clonestudiobd.com/api/dispensers/${id}`,
      config
    );

    dispatch({
      type: DISPENSERS_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: DISPENSERS_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

//PRODUCTS

export const productList = () => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_REQUEST,
    });

    const { data } = await axios.get(
      "http://auto.clonestudiobd.com/api/products"
    );

    dispatch({
      type: PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// export const dimentionsPermissionAction =
//   (user_role_id, app_menu_id) => async (dispatch) => {
//     try {
//       dispatch({
//         type: EMPLOYEES_PERMISSION_REQUEST,
//       });

//       const config = {
//         headers: {
//           Accept: "application/json",
//         },
//       };

//       console.log("DataCheck", user_role_id, app_menu_id);

//       const { data } = await axios.post(
//         "http://bbox.clonestudiobd.com/api/userrolefunctions/permission",
//         {
//           user_role_id: user_role_id,
//           app_menu_id: app_menu_id,
//         },
//         config
//       );

//       console.log("DataP", user_role_id, app_menu_id);

//       dispatch({
//         type: EMPLOYEES_PERMISSION_SUCCESS,
//         payload: data,
//       });
//     } catch (error) {
//       dispatch({
//         type: EMPLOYEES_PERMISSION_FAIL,
//         payload:
//           error.response && error.response.data.error
//             ? error.response.data.error
//             : error.error,
//       });
//     }
//   };
