import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    status,
    setStatus,
    setLogo,
    item,
    value,
    handleInputChange,
    error,
    helperText,
  } = props;

  console.log("edit data", item);

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const handleLogoUpload = (event) => {
    const files = event.target.files;
    setLogo(files[0]);
  };

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="company_name"
              required
              variant="outlined"
              fullWidth
              id="company_name"
              label="Company Name"
              autoFocus
              size="small"
              error={error.company_name}
              defaultValue={item ? item.company_name : null}
              helperText={helperText.company_name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="first_name"
              variant="outlined"
              fullWidth
              id="first_name"
              label="First Name"
              autoFocus
              size="small"
              defaultValue={item ? item.first_name : null}
              error={error.first_name}
              helperText={helperText.first_name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="last_name"
              variant="outlined"
              fullWidth
              id="last_name"
              label="Last Name"
              autoFocus
              size="small"
              defaultValue={item ? item.last_name : null}
              error={error.last_name}
              helperText={helperText.last_name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="address"
              variant="outlined"
              fullWidth
              id="address"
              label="Address"
              autoFocus
              size="small"
              defaultValue={item ? item.address : null}
              error={error.address}
              helperText={helperText.address}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name="phone_no"
              variant="outlined"
              fullWidth
              id="phone_no"
              label="Phone No"
              autoFocus
              size="small"
              defaultValue={item ? item.phone_no : null}
              error={error.phone_no}
              helperText={helperText.phone_no}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="email_address"
              variant="outlined"
              fullWidth
              id="email_address"
              label="Email Address"
              autoFocus
              size="small"
              defaultValue={item ? item.email_address : null}
              error={error.email_address}
              helperText={helperText.email_address}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="password"
              variant="outlined"
              fullWidth
              id="password"
              label="Password"
              autoFocus
              size="small"
              defaultValue={item ? item.password : null}
              error={error.password}
              helperText={helperText.password}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="registration_date"
              variant="outlined"
              required
              fullWidth
              id="registration_date"
              label="Registration Date"
              type="date"
              autoFocus
              size="small"
              defaultValue={item ? item.registration_date : null}
              error={error.registration_date}
              helperText={helperText.registration_date}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="renewal_date"
              variant="outlined"
              required
              fullWidth
              id="renewal_date"
              label="Renewal Date"
              type="date"
              autoFocus
              size="small"
              defaultValue={item ? item.renewal_date : null}
              error={error.renewal_date}
              helperText={helperText.renewal_date}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl>
              <FormLabel style={{ marginBottom: 10 }}>Logo</FormLabel>
              <input
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleLogoUpload}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Status</FormLabel>
              <RadioGroup
                aria-label="status"
                name="status1"
                defaultValue={item ? item.status : status}
                onChange={handleChange}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  value="A"
                  control={<Radio size="small" />}
                  label={
                    <Typography style={{ fontSize: 14 }}>Active</Typography>
                  }
                />
                <FormControlLabel
                  value="D"
                  control={<Radio size="small" />}
                  label={
                    <Typography style={{ fontSize: 14 }}>Deactivate</Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
