import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    value,
    handleInputChange,
    error,
    helperText,
    setRoleId,
    fueltanks,
    products,
    setProductId,
    item,
  } = props;

  console.log("edit data", item);

  // const handleChange = (event) => {
  //   setRoleStatus(event.target.value);
  // };

  var productName = item
    ? products
        .filter((o1) => {
          return o1.id === item.product_id;
        })
        .map((item) => item.product_name)
    : null;

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="tank_name"
              variant="outlined"
              required
              fullWidth
              id="tank_name"
              label="Tank Name"
              autoFocus
              size="small"
              defaultValue={item ? item.tank_name : null}
              error={error.tank_name}
              helperText={helperText.tank_name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="alias"
              variant="outlined"
              required
              fullWidth
              id="alias"
              label="Alias"
              autoFocus
              size="small"
              defaultValue={item ? item.alias : null}
              error={error.alias}
              helperText={helperText.alias}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="capacity"
              variant="outlined"
              required
              fullWidth
              id="capacity"
              label="Capacity"
              autoFocus
              size="small"
              defaultValue={item ? item.capacity : null}
              error={error.capacity}
              helperText={helperText.capacity}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="minimum_stock"
              variant="outlined"
              required
              fullWidth
              id="minimum_stock"
              label="Minimum Stock"
              autoFocus
              size="small"
              defaultValue={item ? item.minimum_stock : null}
              error={error.minimum_stock}
              helperText={helperText.minimum_stock}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                Product *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setProductId(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? productName : "None"}</option>
                {products
                  ? products.map((item) => (
                      <option value={item.id}>{item.product_name}</option>
                    ))
                  : null}
              </NativeSelect>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
