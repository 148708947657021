export const ITEM_GROUP_REQUEST = "ITEM_GROUP_REQUEST";
export const ITEM_GROUP_SUCCESS = "ITEM_GROUP_SUCCESS";
export const ITEM_GROUP_FAIL = "ITEM_GROUP_FAIL";

export const ITEM_GROUP_CREATE_REQUEST = "ITEM_GROUP_CREATE_REQUEST";
export const ITEM_GROUP_CREATE_SUCCESS = "ITEM_GROUP_CREATE_SUCCESS";
export const ITEM_GROUP_CREATE_FAIL = "ITEM_GROUP_CREATE_FAIL";
export const ITEM_GROUP_CREATE_RESET = "ITEM_GROUP_CREATE_RESET";

export const ITEM_GROUP_UPDATE_REQUEST = "ITEM_GROUP_UPDATE_REQUEST";
export const ITEM_GROUP_UPDATE_SUCCESS = "ITEM_GROUP_UPDATE_SUCCESS";
export const ITEM_GROUP_UPDATE_FAIL = "ITEM_GROUP_UPDATE_FAIL";
export const ITEM_GROUP_UPDATE_RESET = "ITEM_GROUP_UPDATE_RESET";

export const ITEM_GROUP_DELETE_REQUEST = "ITEM_GROUP_DELETE_REQUEST";
export const ITEM_GROUP_DELETE_SUCCESS = "ITEM_GROUP_DELETE_SUCCESS";
export const ITEM_GROUP_DELETE_FAIL = "ITEM_GROUP_DELETE_FAIL";

export const ITEM_GROUP_PERMISSION_REQUEST = "ITEM_GROUP_PERMISSION_REQUEST";
export const ITEM_GROUP_PERMISSION_SUCCESS = "ITEM_GROUP_PERMISSION_SUCCESS";
export const ITEM_GROUP_PERMISSION_FAIL = "ITEM_GROUP_PERMISSION_FAIL";

//USER
export const USER_REQUEST = "USER_REQUEST";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAIL = "USER_FAIL";
