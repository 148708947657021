import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
import {
  dispensersList,
  dispensersUpdateAction,
} from "../../../redux/actions/dispenserAction";
import { EditValidation } from "./FormValidation";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <PersonAddIcon style={{ fontSize: 25 }} />
      <Typography
        variant="h6"
        style={{ marginLeft: 20, fontSize: 16, fontWeight: "500" }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Popup = (props) => {
  const {
    openPopup,
    setOpenPopup,
    dispensers,
    products,
    setNotify,
    openEditPopup,
    setOpenEditPopup,
    item,
  } = props;

  const [product_id, setProductId] = React.useState("");

  const id = item.id;

  const device_code = "123456";
  const device_validation = "Y";

  useEffect(() => {
    setProductId(item.product_id);
  }, [item]);

  const dispatch = useDispatch();
  const dispensersUpdate = useSelector((state) => state.dispensersUpdate);
  const { dispensersUpdates } = dispensersUpdate;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("dispenser_name" in fieldValues)
      temp.dispenser_name = fieldValues.dispenser_name
        ? ""
        : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    EditValidation(item, true, validate);
  console.log("employee", values);

  const dispenser_name = values.dispenser_name;
  const alias = values.alias;

  console.log("rrrrrr", id, dispenser_name, alias, product_id);

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(dispensersUpdateAction(id, dispenser_name, alias, product_id));
      setOpenEditPopup(false);
      setTimeout(() => {
        dispatch(dispensersList());
      }, 2000);
      // setNotify({
      //   isOpen: true,
      //   message: 'Submitted Successfully!',
      //   type: 'success',
      // });
      resetForm();
    }
  };

  const handleClose = () => {
    setOpenEditPopup(false);
  };

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={openEditPopup}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Modify Form
      </DialogTitle>
      <DialogContent dividers>
        <InputForm
          setProductId={setProductId}
          dispensers={dispensers}
          products={products}
          item={item}
          value={values}
          handleInputChange={handleInputChange}
          error={errors}
          helperText={errors}
        />
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
