import axios from "axios";
import {
  ITEM_REQUEST,
  ITEM_SUCCESS,
  ITEM_FAIL,
  ITEM_CREATE_REQUEST,
  ITEM_CREATE_SUCCESS,
  ITEM_CREATE_FAIL,
  ITEM_CREATE_RESET,
  ITEM_UPDATE_REQUEST,
  ITEM_UPDATE_SUCCESS,
  ITEM_UPDATE_FAIL,
  ITEM_UPDATE_RESET,
  ITEM_DELETE_REQUEST,
  ITEM_DELETE_SUCCESS,
  ITEM_DELETE_FAIL,
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAIL,
  ITEM_GROUP_REQUEST,
  ITEM_GROUP_SUCCESS,
  ITEM_GROUP_FAIL,
  UOM_REQUEST,
  UOM_SUCCESS,
  UOM_FAIL,
} from "../constants/itemConstant";

export const itemList = () => async (dispatch) => {
  try {
    dispatch({
      type: ITEM_REQUEST,
    });

    const { data } = await axios.get(
      "http://stockmanagement.clonestudiobd.com/api/items"
    );

    dispatch({
      type: ITEM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ITEM_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const itemCreateAction =
  (
    user_id,
    item_group_id,
    item_name,
    uom,
    purchase_value,
    sale_value,
    min_stock,
    max_stock,
    discount_Per,
    vat_per,
    opening_stock,
    created_by,
    modified_by
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ITEM_CREATE_REQUEST,
      });

      const config = {
        headers: {
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        "http://stockmanagement.clonestudiobd.com/api/items",
        {
          user_id,
          item_group_id,
          item_name,
          uom,
          purchase_value,
          sale_value,
          min_stock,
          max_stock,
          discount_Per,
          vat_per,
          opening_stock,
          created_by,
          modified_by,
        },
        config
      );

      console.log(
        "data4",
        user_id,
        item_group_id,
        item_name,
        uom,
        purchase_value,
        sale_value,
        min_stock,
        max_stock,
        discount_Per,
        vat_per,
        opening_stock,
        created_by,
        modified_by
      );

      dispatch({
        type: ITEM_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ITEM_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const itemUpdateAction =
  (
    id,
    user_id,
    item_group_id,
    item_name,
    uom,
    purchase_value,
    sale_value,
    min_stock,
    max_stock,
    discount_Per,
    vat_per,
    opening_stock,
    created_by,
    modified_by
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ITEM_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.put(
        `http://stockmanagement.clonestudiobd.com/api/items/${id}`,
        {
          user_id,
          item_group_id,
          item_name,
          uom,
          purchase_value,
          sale_value,
          min_stock,
          max_stock,
          discount_Per,
          vat_per,
          opening_stock,
          created_by,
          modified_by,
        },
        config
      );

      console.log(
        "update data",
        user_id,
        item_group_id,
        item_name,
        uom,
        purchase_value,
        sale_value,
        min_stock,
        max_stock,
        discount_Per,
        vat_per,
        opening_stock,
        created_by,
        modified_by
      );

      dispatch({
        type: ITEM_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ITEM_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const itemDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ITEM_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(
      `http://stockmanagement.clonestudiobd.com/api/items/${id}`,
      config
    );

    dispatch({
      type: ITEM_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: ITEM_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

//USER

export const userList = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_REQUEST,
    });

    const { data } = await axios.get(
      "http://stockmanagement.clonestudiobd.com/api/users"
    );

    dispatch({
      type: USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

//Groups

export const groupList = () => async (dispatch) => {
  try {
    dispatch({
      type: ITEM_GROUP_REQUEST,
    });

    const { data } = await axios.get(
      "http://stockmanagement.clonestudiobd.com/api/groups"
    );

    dispatch({
      type: ITEM_GROUP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ITEM_GROUP_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

//uom

export const uomList = () => async (dispatch) => {
  try {
    dispatch({
      type: UOM_REQUEST,
    });

    const { data } = await axios.get(
      "http://stockmanagement.clonestudiobd.com/api/uoms"
    );

    dispatch({
      type: UOM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UOM_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// export const dimentionsPermissionAction =
//   (user_role_id, app_menu_id) => async (dispatch) => {
//     try {
//       dispatch({
//         type: PRODUCT_PERMISSION_REQUEST,
//       });

//       const config = {
//         headers: {
//           Accept: "application/json",
//         },
//       };

//       console.log("DataCheck", user_role_id, app_menu_id);

//       const { data } = await axios.post(
//         "http://bbox.clonestudiobd.com/api/userrolefunctions/permission",
//         {
//           user_role_id: user_role_id,
//           app_menu_id: app_menu_id,
//         },
//         config
//       );

//       console.log("DataP", user_role_id, app_menu_id);

//       dispatch({
//         type: PRODUCT_PERMISSION_SUCCESS,
//         payload: data,
//       });
//     } catch (error) {
//       dispatch({
//         type: PRODUCT_PERMISSION_FAIL,
//         payload:
//           error.response && error.response.data.error
//             ? error.response.data.error
//             : error.error,
//       });
//     }
//   };
