import React, { useEffect, useState } from "react";

import MeasurementTable from "./MeasurementTable";

import { useDispatch, useSelector } from "react-redux";
import { measurementDataList } from "../../../redux/actions/measuringActions";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Icon from "@material-ui/core/Icon";
import { permissionAction } from "../../../redux/actions/permissionActions";

const MeasurementMain = (props) => {
  const { userData } = props;
  const dispatch = useDispatch();
  const [app_menu_id, setAppMenuId] = useState(14);

  const measurementData = useSelector((state) => state.measurementData);
  const { measurementState } = measurementData;
  console.log("from measTable:", measurementState);

  const permission = useSelector((state) => state.permission);

  const { permissions } = permission;

  console.log("permission - xyz", permissions);
  console.log(userData);
  React.useEffect(() => {
    dispatch(measurementDataList());
    dispatch(permissionAction(userData, app_menu_id));
  }, [dispatch, userData]);

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Icon style={{ fontSize: 25, marginTop: 18 }}>calculate</Icon>
        <h2 style={{ marginLeft: 5 }}>Measuring Units</h2>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: -30,
          fontSize: 11,
          marginLeft: 25,
        }}
      >
        <p>Elements</p>
        {/* <Icon style={{ fontSize: 14, marginTop: 12 }}>navigate_next</Icon> */}
        <NavigateNextIcon style={{ fontSize: 14, marginTop: 12 }}>
          navigate_next
        </NavigateNextIcon>
        <p>Measuring Units</p>
      </div>
      <div>
        <MeasurementTable
          measurements={measurementState}
          permissions={permissions}
        ></MeasurementTable>
      </div>
    </div>
  );
};

export default MeasurementMain;
