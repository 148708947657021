import React, { useState, useEffect } from 'react';

export function CreateValidation(validateOnChange = false, validate) {
  const [values, setValues] = useState({
    role_name: '',
    role_description: '',
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  console.log('bbb', values);

  const resetForm = () => {
    setValues(values);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}

export function EditValidation(item, validateOnChange = false, validate) {
  const [values, setValues] = useState({
    role_name: item ? item.role_name : '',
    role_description: item ? item.role_description : '',
  });
  const [errors, setErrors] = useState({});

  console.log('val', values);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  useEffect(() => {
    setValues({
      role_name: item.role_name,
      role_description: item.role_description,
    });
  }, [item]);

  console.log('bbb', values);

  const resetForm = () => {
    setValues(values);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}
