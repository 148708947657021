import axios from "axios";

import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAIL,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_PERMISSION_REQUEST,
  USER_PERMISSION_SUCCESS,
  USER_PERMISSION_FAIL,
} from "../constants/userConstants";

export const login = (user_id, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.post(
      "http://auto.clonestudiobd.com/api/auth/login",
      { user_id, password },
      config
    );

    console.log("data23", data);

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    // localStorage.setItem("userInfo", JSON.stringify(data));
    sessionStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const logout = () => (dispatch) => {
  sessionStorage.clear();
  dispatch({ type: USER_LOGOUT });
};

//user list

// export const userList = () => async (dispatch) => {
//   try {
//     dispatch({
//       type: USER_LIST_REQUEST,
//     });

//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//       },
//     };

//     const { data } = await axios.get(
//       "http://auto.clonestudiobd.com/api/users",
//       config
//     );

//     dispatch({
//       type: USER_LIST_SUCCESS,
//       payload: data,
//     });
//   } catch (error) {
//     dispatch({
//       type: USER_LIST_FAIL,
//       payload:
//         error.response && error.response.data.error
//           ? error.response.data.error
//           : error.error,
//     });
//   }
// };

// export const userCreateAction =
//   (
//     first_name,
//     last_name,
//     phone_no,
//     email,
//     user_id,
//     password,
//     employee_id,
//     photo,
//     sign_photo,
//     user_status,
//     device_code,
//     device_validation,
//     user_role_id
//   ) =>
//   async (dispatch) => {
//     try {
//       dispatch({
//         type: USER_CREATE_REQUEST,
//       });

//       const formData = new FormData();
//       formData.append("first_name", first_name);
//       formData.append("last_name", last_name);
//       formData.append("phone_no", phone_no);
//       formData.append("email", email);
//       formData.append("user_id", user_id);
//       formData.append("password", password);
//       formData.append("employee_id", employee_id);
//       formData.append("photo", photo);
//       formData.append("user_status", user_status);
//       formData.append("device_code", device_code);
//       formData.append("device_validation", device_validation);
//       formData.append("user_role_id", user_role_id);
//       formData.append("sign_photo", sign_photo);

//       console.log("data", formData);

//       const config = {
//         headers: {},
//       };

//       const { data } = await axios
//         .post("http://auto.clonestudiobd.com/api/users", formData, config)
//         .then((res) => {
//           console.log(res.data);
//         });

//       dispatch({
//         type: USER_CREATE_SUCCESS,
//         payload: data,
//       });
//     } catch (error) {
//       dispatch({
//         type: USER_CREATE_FAIL,
//         payload:
//           error.response && error.response.data.errors
//             ? error.response.data.errors
//             : error.errors,
//       });
//     }
//   };

// export const userUpdateAction =
//   (
//     id,
//     first_name,
//     last_name,
//     phone_no,
//     email,
//     user_id,
//     password,
//     employee_id,
//     photo,
//     sign_photo,
//     user_status,
//     device_code,
//     device_validation,
//     user_role_id
//   ) =>
//   async (dispatch) => {
//     try {
//       dispatch({
//         type: USER_UPDATE_REQUEST,
//       });

//       console.log("id", id);

//       console.log("first_name", first_name);
//       console.log("last_name", last_name);
//       console.log("phone_no", phone_no);
//       console.log("email", email);
//       console.log("user_id", user_id);
//       console.log("employee_id", employee_id);
//       console.log("photo", photo);
//       console.log("password", password);
//       console.log("user_role_id", user_role_id);

//       console.log("sign_photo", sign_photo);

//       const formData = new FormData();
//       formData.append("first_name", first_name);
//       formData.append("last_name", last_name);
//       formData.append("phone_no", phone_no);
//       formData.append("email", email);
//       formData.append("user_id", user_id);
//       formData.append("user_password", password);
//       formData.append("employee_id", employee_id);
//       formData.append("photo", photo);
//       formData.append("user_status", user_status);
//       formData.append("device_code", device_code);
//       formData.append("device_validation", device_validation);
//       formData.append("user_role_id", user_role_id);
//       formData.append("sign_photo", sign_photo);

//       console.log("data", formData);

//       const config = {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//         },
//       };

//       const { data } = await axios
//         .post(
//           `http://auto.clonestudiobd.com/api/users/${id}`,
//           {
//             first_name: first_name,
//             last_name: last_name,
//             phone_no: phone_no,
//             email: email,
//             user_id: user_id,
//             password: password,
//             user_status: user_status,
//             device_code: device_code,
//             device_validation: device_validation,
//             user_role_id: user_role_id,
//           },
//           config
//         )
//         .then((res) => {
//           console.log("abhbcd", res.data);
//         });

//       dispatch({
//         type: USER_UPDATE_SUCCESS,
//         payload: data,
//       });
//     } catch (error) {
//       dispatch({
//         type: USER_UPDATE_FAIL,
//         payload:
//           error.response && error.response.data.errors
//             ? error.response.data.errors
//             : error.errors,
//       });
//     }
//   };

// export const userDeleteAction = (id) => async (dispatch) => {
//   try {
//     dispatch({
//       type: USER_DELETE_REQUEST,
//     });

//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//       },
//     };

//     console.log("id", id);

//     await axios.delete(`http://auto.clonestudiobd.com/api/users/${id}`, config);

//     dispatch({
//       type: USER_DELETE_SUCCESS,
//     });
//   } catch (error) {
//     dispatch({
//       type: USER_DELETE_FAIL,
//       payload:
//         error.response && error.response.data.error
//           ? error.response.data.error
//           : error.error,
//     });
//   }
// };

export const userPermissionAction = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_PERMISSION_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(
      "http://auto.clonestudiobd.com/api/users",
      config
    );

    dispatch({
      type: USER_PERMISSION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_PERMISSION_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const userList = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_REQUEST,
    });

    const { data } = await axios.get(
      "http://stockmanagement.clonestudiobd.com/api/users"
    );

    dispatch({
      type: USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const userCreateAction =
  (
    company_name,
    first_name,
    last_name,
    address,
    phone_no,
    email_address,
    password,
    logo,
    registration_date,
    renewal_date,
    status,
    created_by,
    modified_by
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_CREATE_REQUEST,
      });

      const formData = new FormData();
      formData.append("company_name", company_name);
      formData.append("first_name", first_name);
      formData.append("last_name", last_name);
      formData.append("address", address);
      formData.append("phone_no", phone_no);
      formData.append("email_address", email_address);
      formData.append("password", password);
      formData.append("logo", logo);
      formData.append("registration_date", registration_date);
      formData.append("renewal_date", renewal_date);
      formData.append("status", status);

      console.log("formData", formData);

      const config = {
        headers: {
          Accept: "application/json",
        },
      };

      const { data } = await axios
        .post(
          "http://stockmanagement.clonestudiobd.com/api/users",
          formData,
          config
        )
        .then((res) => {
          console.log(res.data);
        });

      console.log("testDAta", data);

      dispatch({
        type: USER_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const userUpdateAction =
  (
    id,
    company_name,
    first_name,
    last_name,
    address,
    phone_no,
    email_address,
    password,
    logo,
    registration_date,
    renewal_date,
    status,
    created_by,
    modified_by
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_UPDATE_REQUEST,
      });

      console.log("id", id);

      console.log("company_name", company_name);
      console.log("first_name", first_name);
      console.log("last_name", last_name);
      console.log("address", address);
      console.log("phone_no", phone_no);
      console.log("email_address", email_address);
      console.log("password", password);
      console.log("logo", logo);
      console.log("registration_date", registration_date);
      console.log("renewal_date", renewal_date);
      console.log("status", status);

      const formData = new FormData();
      formData.append("company_name", company_name);
      formData.append("first_name", first_name);
      formData.append("last_name", last_name);
      formData.append("address", address);
      formData.append("phone_no", phone_no);
      formData.append("email_address", email_address);
      formData.append("password", password);
      formData.append("logo", logo);
      formData.append("registration_date", registration_date);
      formData.append("renewal_date", renewal_date);
      formData.append("status", status);

      console.log("Data", formData);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios
        .post(
          `http://stockmanagement.clonestudiobd.com/api/users/${id}`,
          {
            company_name: company_name,
            first_name: first_name,
            last_name: last_name,
            address: address,
            phone_no: phone_no,
            email_address: email_address,
            password: password,
            logo: logo,
            registration_date: registration_date,
            renewal_date: renewal_date,
            status: status,
          },
          config
        )
        .then((res) => {
          console.log("abhbcd", res.data);
        });

      console.log(
        "update data",
        company_name,
        first_name,
        last_name,
        address,
        phone_no,
        email_address,
        password,
        logo,
        registration_date,
        renewal_date,
        status,
        created_by,
        modified_by
      );

      dispatch({
        type: USER_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const userDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(
      `http://stockmanagement.clonestudiobd.com/api/users/${id}`,
      config
    );

    dispatch({
      type: USER_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: USER_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
