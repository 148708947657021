import axios from "axios";
import {
  SHIFT_REQUEST,
  SHIFT_SUCCESS,
  SHIFT_FAIL,
  SHIFT_CREATE_REQUEST,
  SHIFT_CREATE_SUCCESS,
  SHIFT_CREATE_FAIL,
  SHIFT_CREATE_RESET,
  SHIFT_UPDATE_REQUEST,
  SHIFT_UPDATE_SUCCESS,
  SHIFT_UPDATE_FAIL,
  SHIFT_UPDATE_RESET,
  SHIFT_DELETE_REQUEST,
  SHIFT_DELETE_SUCCESS,
  SHIFT_DELETE_FAIL,
  SHIFT_PERMISSION_REQUEST,
  SHIFT_PERMISSION_SUCCESS,
  SHIFT_PERMISSION_FAIL,
} from "../constants/shiftConstant";

export const shiftList = () => async (dispatch) => {
  try {
    dispatch({
      type: SHIFT_REQUEST,
    });

    const { data } = await axios.get(
      "http://auto.clonestudiobd.com/api/shifts"
    );

    dispatch({
      type: SHIFT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SHIFT_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const shiftCreateAction =
  (
    shift,
    alias,
    in_hour,
    in_min,
    out_hour,
    out_min,
    print_serial,
    created_by,
    modified_by
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SHIFT_CREATE_REQUEST,
      });

      const config = {
        headers: {
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        "http://auto.clonestudiobd.com/api/shifts",
        {
          shift,
          alias,
          in_hour,
          in_min,
          out_hour,
          out_min,
          print_serial,
          created_by,
          modified_by,
        },
        config
      );

      console.log(
        "data4",
        shift,
        alias,
        in_hour,
        in_min,
        out_hour,
        out_min,
        print_serial,
        created_by,
        modified_by
      );

      dispatch({
        type: SHIFT_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SHIFT_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const shiftUpdateAction =
  (
    id,
    shift,
    alias,
    in_hour,
    in_min,
    out_hour,
    out_min,
    print_serial,
    created_by,
    modified_by
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SHIFT_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        `http://auto.clonestudiobd.com/api/shifts/${id}`,
        {
          shift,
          alias,
          in_hour,
          in_min,
          out_hour,
          out_min,
          print_serial,
          created_by,
          modified_by,
        },
        config
      );

      console.log(
        "update data",
        shift,
        alias,
        in_hour,
        in_min,
        out_hour,
        out_min,
        print_serial,
        created_by,
        modified_by
      );

      dispatch({
        type: SHIFT_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SHIFT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const shiftDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SHIFT_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(
      `http://auto.clonestudiobd.com/api/shifts/${id}`,
      config
    );

    dispatch({
      type: SHIFT_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: SHIFT_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// export const dimentionsPermissionAction =
//   (user_role_id, app_menu_id) => async (dispatch) => {
//     try {
//       dispatch({
//         type: PRODUCT_PERMISSION_REQUEST,
//       });

//       const config = {
//         headers: {
//           Accept: "application/json",
//         },
//       };

//       console.log("DataCheck", user_role_id, app_menu_id);

//       const { data } = await axios.post(
//         "http://bbox.clonestudiobd.com/api/userrolefunctions/permission",
//         {
//           user_role_id: user_role_id,
//           app_menu_id: app_menu_id,
//         },
//         config
//       );

//       console.log("DataP", user_role_id, app_menu_id);

//       dispatch({
//         type: PRODUCT_PERMISSION_SUCCESS,
//         payload: data,
//       });
//     } catch (error) {
//       dispatch({
//         type: PRODUCT_PERMISSION_FAIL,
//         payload:
//           error.response && error.response.data.error
//             ? error.response.data.error
//             : error.error,
//       });
//     }
//   };
