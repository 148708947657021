export const MEASUREMENT_REQUEST = 'MEASUREMENT_REQUEST';
export const MEASUREMENT_SUCCESS = 'MEASUREMENT_SUCCESS';
export const MEASUREMENT_FAIL = 'MEASUREMENT_FAIL';

export const MEASUREMENT_CREATE_REQUEST = 'MEASUREMENT_CREATE_REQUEST';
export const MEASUREMENT_CREATE_SUCCESS = 'MEASUREMENT_CREATE_SUCCESS';
export const MEASUREMENT_CREATE_FAIL = 'MEASUREMENT_CREATE_FAIL';
export const MEASUREMENT_CREATE_RESET = 'MEASUREMENT_CREATE_RESET';

export const MEASUREMENT_UPDATE_REQUEST = 'MEASUREMENT_UPDATE_REQUEST';
export const MEASUREMENT_UPDATE_SUCCESS = 'MEASUREMENT_UPDATE_SUCCESS';
export const MEASUREMENT_UPDATE_FAIL = 'MEASUREMENT_UPDATE_FAIL';
export const MEASUREMENT_UPDATE_RESET = 'MEASUREMENT_UPDATE_RESET';

export const MEASUREMENT_DELETE_REQUEST = 'MEASUREMENT_DELETE_REQUEST';
export const MEASUREMENT_DELETE_SUCCESS = 'MEASUREMENT_DELETE_SUCCESS';
export const MEASUREMENT_DELETE_FAIL = 'MEASUREMENT_DELETE_FAIL';
