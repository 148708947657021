import React, { useState, useEffect } from 'react';

export function CreateValidation(validateOnChange = false, validate) {
  const [values, setValues] = useState({
    designation: '',
    alias: '',
    print_serial: '',
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  console.log('bbb', values);

  const resetForm = () => {
    setValues(values);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}

export function EditValidation(item, validateOnChange = false, validate) {
  const [values, setValues] = useState({
    designation: item ? item.designation : '',
    alias: item ? item.alias : '',
    print_serial: item ? item.print_serial : '',
  });
  const [errors, setErrors] = useState({});

  console.log('val', values);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  useEffect(() => {
    setValues({
      designation: item.designation,
      alias: item.alias,
      print_serial: item.print_serial,
    });
  }, [item]);

  console.log('bbb', values);

  const resetForm = () => {
    setValues(values);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}
