import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    setPhoto,
    users,
    setUserId,
    item,
    value,
    handleInputChange,
    error,
    helperText,
  } = props;

  var userName = item
    ? users
        .filter((o1) => {
          return o1.id === item.user_id;
        })
        .map((item) => item.first_name)
    : null;

  console.log("edit data", item);

  const handlePhotoUpload = (event) => {
    const files = event.target.files;
    setPhoto(files[0]);
  };

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                User Name *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setUserId(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? userName : "None"}</option>
                {users
                  ? users.map((item) => (
                      <option value={item.id}>{item.first_name}</option>
                    ))
                  : null}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="account_type"
              variant="outlined"
              fullWidth
              id="account_type"
              label="Account Type"
              autoFocus
              size="small"
              defaultValue={item ? item.account_type : null}
              value={value.account_type}
              error={error.account_type}
              helperText={helperText.account_type}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="account_name"
              variant="outlined"
              fullWidth
              id="account_name"
              label="Account Name"
              autoFocus
              size="small"
              defaultValue={item ? item.account_name : null}
              // onChange={(e) => setStreet(e.target.value)}
              value={value.account_name}
              error={error.account_name}
              helperText={helperText.account_name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="address"
              variant="outlined"
              fullWidth
              id="address"
              label="Address"
              autoFocus
              size="small"
              defaultValue={item ? item.address : null}
              // onChange={(e) => setCity(e.target.value)}
              value={value.address}
              error={error.address}
              helperText={helperText.address}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="opening_balance"
              variant="outlined"
              fullWidth
              id="opening_balance"
              label="Opening Balance"
              autoFocus
              size="small"
              defaultValue={item ? item.opening_balance : null}
              // onChange={(e) => setZipCode(e.target.value)}
              value={value.opening_balance}
              error={error.opening_balance}
              helperText={helperText.opening_balance}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="mobile_no"
              variant="outlined"
              fullWidth
              id="mobile_no"
              label="Mobile No"
              autoFocus
              size="small"
              defaultValue={item ? item.mobile_no : null}
              // onChange={(e) => setPhoneNo(e.target.value)}
              value={value.mobile_no}
              error={error.mobile_no}
              helperText={helperText.mobile_no}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="email_address"
              variant="outlined"
              fullWidth
              id="email_address"
              label="Email Address"
              autoFocus
              size="small"
              defaultValue={item ? item.email_address : null}
              // onChange={(e) => setEmailAddress(e.target.value)}
              value={value.email_address}
              error={error.email_address}
              helperText={helperText.email_address}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl>
              <FormLabel style={{ marginBottom: 10 }}>Photo</FormLabel>
              <input
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                onChange={handlePhotoUpload}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name="remarks"
              variant="outlined"
              fullWidth
              id="remarks"
              label="remarks"
              autoFocus
              size="small"
              defaultValue={item ? item.remarks : null}
              value={value.remarks}
              error={error.remarks}
              helperText={helperText.remarks}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
