import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import Tooltip from "@material-ui/core/Tooltip";
import AppMenuItemComponent from "./ModuleChildreenItems";

const ModuleItem = ({ isSidebarOpened, ...item }) => {
  const {
    menu_caption,
    menu_link,
    parent_menu,
    menu_icon,
    children = [],
  } = item;
  const classes = useStyles();
  const isExpandable = children && children.length > 0;
  const [open, setOpen] = React.useState(false);

  function handleClick() {
    setOpen(!open);
  }
  console.log("menu33", menu_caption);

  const MenuItemRoot = (
    <AppMenuItemComponent
      className={classes.menuItem}
      menu_link={menu_link}
      parent_menu={parent_menu}
      onClick={handleClick}
    >
      {/* Display an icon if any */}
      {!!menu_icon && (
        <Tooltip
          title={menu_caption}
          placement="right"
          interactive
          disableHoverListener={isSidebarOpened}
        >
          <ListItemIcon
            className={clsx(classes.menuItemIcon, {
              [classes.menuItemCollapseIcon]: !isSidebarOpened,
            })}
          >
            <Icon style={{ fontSize: 16 }}>{menu_icon}</Icon>
          </ListItemIcon>
        </Tooltip>
      )}
      <ListItemText
        classes={{
          primary: clsx({
            [classes.listItemText]: isSidebarOpened,
            [classes.listItemCollapseText]: !isSidebarOpened,
          }),
        }}
        primary={menu_caption}
        inset={!menu_icon}
      />
      {/* Display the expand menu if the item has children */}
      {isExpandable && !open && <IconExpandMore style={{ fontSize: 10 }} />}
      {isExpandable && open && <IconExpandLess style={{ fontSize: 10 }} />}
    </AppMenuItemComponent>
  );

  // const MenuItemChildren = isExpandable ? (
  //   <Collapse in={open} timeout='auto' unmountOnExit>
  //     <Divider />
  //     <List component='div' disablePadding>
  //       {children.map((item, index) => (
  //         <AppMenuItem {...item} key={index} />
  //       ))}
  //     </List>
  //   </Collapse>
  // ) : null;

  return (
    <>
      {MenuItemRoot}
      {/* {MenuItemChildren} */}
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    menuItem: {
      "&.active": {
        background: "#FDB750",
        "& .MuiListItemIcon-root": {
          color: "#fff",
          fontWeight: "bold",
        },
      },
      borderRadius: 2,
      height: 40,
    },
    menuItemIcon: {
      color: "#FDB750",
      marginLeft: 30,
    },
    listItemText: {
      fontSize: 13,
      color: "white",
      marginLeft: -20,
    },
    menuItemCollapseIcon: {
      color: "#FDB750",
      marginLeft: 5,
      fontSize: 12,
    },
    listItemCollapseText: {
      fontSize: 13,
      color: "white",
    },
  })
);

export default ModuleItem;
