import axios from "axios";
import {
  MEASUREMENT_REQUEST,
  MEASUREMENT_SUCCESS,
  MEASUREMENT_FAIL,
  MEASUREMENT_CREATE_REQUEST,
  MEASUREMENT_CREATE_SUCCESS,
  MEASUREMENT_CREATE_FAIL,
  MEASUREMENT_UPDATE_REQUEST,
  MEASUREMENT_UPDATE_SUCCESS,
  MEASUREMENT_UPDATE_FAIL,
  MEASUREMENT_DELETE_REQUEST,
  MEASUREMENT_DELETE_SUCCESS,
  MEASUREMENT_DELETE_FAIL,
} from "../constants/constants";

export const measurementDataList = () => async (dispatch) => {
  try {
    dispatch({ type: MEASUREMENT_REQUEST });
    const { data } = await axios.get(
      "http://auto.clonestudiobd.com/api/measuring-units"
    );
    dispatch({
      type: MEASUREMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MEASUREMENT_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const measurementCreateAction =
  (uom, description) => async (dispatch) => {
    try {
      dispatch({
        type: MEASUREMENT_CREATE_REQUEST,
      });
      const config = {
        headers: {
          Accept: "application/json",
        },
      };
      const { data } = await axios.post(
        "http://auto.clonestudiobd.com/api/measuring-units",
        {
          uom,
          description,
        },
        config
      );
      console.log("from actions:", uom, description);

      dispatch({
        type: MEASUREMENT_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MEASUREMENT_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const measurementUpdateAction =
  (id, uom, description) => async (dispatch) => {
    try {
      dispatch({
        type: MEASUREMENT_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        `http://auto.clonestudiobd.com/api/measuring-units/${id}`,
        {
          uom,
          description,
        },
        config
      );

      //   console.log(
      //     'update data',

      //     // created_by,
      //     // modified_by
      //   );

      dispatch({
        type: MEASUREMENT_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MEASUREMENT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const measurementDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: MEASUREMENT_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(
      `http://auto.clonestudiobd.com/api/measuring-units/${id}`,
      config
    );

    dispatch({
      type: MEASUREMENT_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: MEASUREMENT_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
