import {
  EMPLOYEE_REQUEST,
  EMPLOYEE_SUCCESS,
  EMPLOYEE_FAIL,
  EMPLOYEE_CREATE_REQUEST,
  EMPLOYEE_CREATE_SUCCESS,
  EMPLOYEE_CREATE_FAIL,
  EMPLOYEE_CREATE_RESET,
  EMPLOYEE_UPDATE_REQUEST,
  EMPLOYEE_UPDATE_SUCCESS,
  EMPLOYEE_UPDATE_FAIL,
  EMPLOYEE_UPDATE_RESET,
  EMPLOYEE_DELETE_REQUEST,
  EMPLOYEE_DELETE_SUCCESS,
  EMPLOYEE_DELETE_FAIL,
  EMPLOYEE_PERMISSION_REQUEST,
  EMPLOYEE_PERMISSION_SUCCESS,
  EMPLOYEE_PERMISSION_FAIL,
  SHIFT_REQUEST,
  SHIFT_SUCCESS,
  SHIFT_FAIL,
  DESIGNATIONS_REQUEST,
  DESIGNATIONS_SUCCESS,
  DESIGNATIONS_FAIL,
} from "../constants/employeeConstant";

export const employeeReducer = (state = { employees: [] }, action) => {
  switch (action.type) {
    case EMPLOYEE_REQUEST:
      return { loading: true, employees: [] };
    case EMPLOYEE_SUCCESS:
      return { loading: false, employees: action.payload };
    case EMPLOYEE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const employeeCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_CREATE_REQUEST:
      return { loading: true };
    case EMPLOYEE_CREATE_SUCCESS:
      return { loading: false, employeeCreate: action.payload };
    case EMPLOYEE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case EMPLOYEE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const employeeUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_UPDATE_REQUEST:
      return { loading: true };
    case EMPLOYEE_UPDATE_SUCCESS:
      return { loading: false, employeeUpdate: action.payload };
    case EMPLOYEE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case EMPLOYEE_UPDATE_RESET:
      return { employeeCreate: {} };
    default:
      return state;
  }
};

export const employeeDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_DELETE_REQUEST:
      return { loading: true };
    case EMPLOYEE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case EMPLOYEE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//DESIGNATION
export const designationReducer = (state = { designations: [] }, action) => {
  switch (action.type) {
    case DESIGNATIONS_REQUEST:
      return { loading: true, designations: [] };
    case DESIGNATIONS_SUCCESS:
      return { loading: false, designations: action.payload };
    case DESIGNATIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//SHIFT

export const shiftReducer = (state = { shifts: [] }, action) => {
  switch (action.type) {
    case SHIFT_REQUEST:
      return { loading: true, shifts: [] };
    case SHIFT_SUCCESS:
      return { loading: false, shifts: action.payload };
    case SHIFT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
// export const dimentionsPermissionReducer = (
//   state = { departmentPermission: [] },
//   action
// ) => {
//   switch (action.type) {
//     case EMPLOYEES_PERMISSION_REQUEST:
//       return { loading: true, departmentPermission: [] };
//     case EMPLOYEES_PERMISSION_SUCCESS:
//       return { loading: false, departmentPermission: action.payload };
//     case EMPLOYEES_PERMISSION_FAIL:
//       return { loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };
