export const EMPLOYEE_REQUEST = "EMPLOYEE_REQUEST";
export const EMPLOYEE_SUCCESS = "EMPLOYEE_SUCCESS";
export const EMPLOYEE_FAIL = "EMPLOYEE_FAIL";

export const EMPLOYEE_CREATE_REQUEST = "EMPLOYEE_CREATE_REQUEST";
export const EMPLOYEE_CREATE_SUCCESS = "EMPLOYEE_CREATE_SUCCESS";
export const EMPLOYEE_CREATE_FAIL = "EMPLOYEE_CREATE_FAIL";
export const EMPLOYEE_CREATE_RESET = "EMPLOYEE_CREATE_RESET";

export const EMPLOYEE_UPDATE_REQUEST = "EMPLOYEE_UPDATE_REQUEST";
export const EMPLOYEE_UPDATE_SUCCESS = "EMPLOYEE_UPDATE_SUCCESS";
export const EMPLOYEE_UPDATE_FAIL = "EMPLOYEE_UPDATE_FAIL";
export const EMPLOYEE_UPDATE_RESET = "EMPLOYEE_UPDATE_RESET";

export const EMPLOYEE_DELETE_REQUEST = "EMPLOYEE_DELETE_REQUEST";
export const EMPLOYEE_DELETE_SUCCESS = "EMPLOYEE_DELETE_SUCCESS";
export const EMPLOYEE_DELETE_FAIL = "EMPLOYEE_DELETE_FAIL";

export const EMPLOYEE_PERMISSION_REQUEST = "EMPLOYEE_PERMISSION_REQUEST";
export const EMPLOYEE_PERMISSION_SUCCESS = "EMPLOYEE_PERMISSION_SUCCESS";
export const EMPLOYEE_PERMISSION_FAIL = "EMPLOYEE_PERMISSION_FAIL";

//SHIFT
export const SHIFT_REQUEST = "SHIFT_REQUEST";
export const SHIFT_SUCCESS = "SHIFT_SUCCESS";
export const SHIFT_FAIL = "SHIFT_FAIL";

//designation
export const DESIGNATIONS_REQUEST = "DESIGNATIONS_REQUEST";
export const DESIGNATIONS_SUCCESS = "DESIGNATIONS_SUCCESS";
export const DESIGNATIONS_FAIL = "DESIGNATIONS_FAIL";
