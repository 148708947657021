import axios from "axios";
import {
  DESIGNATIONS_REQUEST,
  DESIGNATIONS_SUCCESS,
  DESIGNATIONS_FAIL,
  DESIGNATIONS_CREATE_REQUEST,
  DESIGNATIONS_CREATE_SUCCESS,
  DESIGNATIONS_CREATE_FAIL,
  DESIGNATIONS_UPDATE_REQUEST,
  DESIGNATIONS_UPDATE_SUCCESS,
  DESIGNATIONS_UPDATE_FAIL,
  DESIGNATIONS_DELETE_REQUEST,
  DESIGNATIONS_DELETE_SUCCESS,
  DESIGNATIONS_DELETE_FAIL,
} from "../constants/designationConstant";

export const designationList = () => async (dispatch) => {
  try {
    dispatch({
      type: DESIGNATIONS_REQUEST,
    });
    const { data } = await axios.get(
      "http://auto.clonestudiobd.com/api/designations"
    );
    dispatch({
      type: DESIGNATIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DESIGNATIONS_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const designationCreateAction =
  (designation, alias, print_serial, created_by, modified_by) =>
  async (dispatch) => {
    try {
      dispatch({
        type: DESIGNATIONS_CREATE_REQUEST,
      });

      const config = {
        headers: {
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        "http://auto.clonestudiobd.com/api/designations",
        {
          designation,
          alias,
          print_serial,
          // created_by,
          // modified_by,
        },
        config
      );

      console.log(
        "data4",
        designation,
        alias,
        print_serial
        // created_by,
        // modified_by
      );

      dispatch({
        type: DESIGNATIONS_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DESIGNATIONS_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const designationUpdateAction =
  (id, designation, alias, print_serial, created_by, modified_by) =>
  async (dispatch) => {
    try {
      dispatch({
        type: DESIGNATIONS_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.put(
        `http://auto.clonestudiobd.com/api/designations/${id}`,
        {
          designation,
          alias,
          print_serial,
          created_by,
          modified_by,
        },
        config
      );

      console.log(
        "update data",
        designation,
        alias,
        print_serial
        // created_by,
        // modified_by
      );

      dispatch({
        type: DESIGNATIONS_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DESIGNATIONS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const designationDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DESIGNATIONS_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(
      `http://auto.clonestudiobd.com/api/designations/${id}`,
      config
    );

    dispatch({
      type: DESIGNATIONS_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: DESIGNATIONS_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
