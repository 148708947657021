import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";
import { Box, Typography, Link } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

import Icon from "@mdi/react";

import { useDispatch, useSelector } from "react-redux";
import { toggleLeftSidebar } from "../redux/actions/globalStateActions";

//icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from "@mdi/js";
import Fab from "@material-ui/core/Fab";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
// styles
import useStyles from "./styles";

// components
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import Dashboard from "../pages/Dashboard";
import UserRoles from "../modules/settings/userRoles";
import UserRoleFunctions from "../modules/settings/roleFunctions";
import User from "../modules/settings/user";
import Product from "../modules/elements/products";
import FualTanks from "../modules/elements/fuelTanks";
import Shifts from "../modules/elements/shifts";
import Designation from "../modules/elements/designations";
import MeasuringUnits from "../modules/elements/measuringUnits";
import Employees from "../modules/elements/employees";
import Dispenser from "../modules/elements/dispensers";
import UOM from "../modules/settings/uoms";
import ItemGroup from "../modules/settings/itemGroups";
import Item from "../modules/settings/items";
import Account from "../modules/settings/accounts";

function Layout(props) {
  var classes = useStyles();
  var theme = useTheme();
  const [leftOpen, setLeftOpen] = useState(true);
  const [rightOpen, setRightOpen] = useState(false);

  const handleDrawerOpen = () => {
    setLeftOpen(true);
  };

  const handleDrawerClose = () => {
    setLeftOpen(false);
  };

  const dispatch = useDispatch();

  const leftSidebar = useSelector((state) => state.leftSidebar);
  const { isSidebarOpened } = leftSidebar;

  var [isPermanent, setPermanent] = useState(true);
  const [user, setUser] = useState("");

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  useEffect(() => {
    const getToken = JSON.parse(localStorage.getItem("userInfo"));

    const session = sessionStorage.getItem("userInfo")
      ? JSON.parse(sessionStorage.getItem("userInfo"))
      : null;

    const token = "Bearer " + session.access_token;

    console.log("session", session);

    fetch("http://auto.clonestudiobd.com/api/auth/me", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
    }).then((response) => {
      response.json().then((userData) => {
        console.log("Checking user Data", userData);
        setUser(userData);
      });
    });
  }, []);

  console.log("user-data", user);

  const token = sessionStorage.getItem("userInfo");

  useEffect(() => {
    dispatch(toggleLeftSidebar);
  }, [dispatch]);

  if (!token) {
    <Redirect to="/login" />;
  }

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} userData={user} />
        <Sidebar />

        <div
          className={classnames(classes.content, {
            [classes.contentShift]: isSidebarOpened,
          })}
        >
          <div
            className={classnames(classes.fab, {
              [classes.fabShift]: isSidebarOpened,
            })}
          >
            {isPermanent ? (
              <div
                className={classes.fabFixed}
                onClick={() => {
                  dispatch(toggleLeftSidebar);
                }}
              >
                {isSidebarOpened ? (
                  <NavigateBeforeIcon
                    style={{ color: "white", fontSize: 17 }}
                  />
                ) : (
                  <NavigateNextIcon style={{ color: "white", fontSize: 17 }} />
                )}
              </div>
            ) : null}
          </div>
          <div className={classes.fakeToolbar} />

          <div
            style={{
              paddingLeft: isPermanent ? 50 : 10,
              paddingRight: isPermanent ? 50 : 10,
            }}
          >
            <Switch>
              <Route path="/app/me">
                <Dashboard userData={user} />
              </Route>
              <Route path="/app/users">
                <User userData={user} />
              </Route>
              <Route path="/app/user-roles">
                <UserRoles userData={user} />
              </Route>
              <Route path="/app/user-role-functions">
                <UserRoleFunctions userData={user} />
              </Route>

              <Route path="/app/products">
                <Product userData={user} />
              </Route>

              <Route path="/app/fuel-tanks">
                <FualTanks userData={user} />
              </Route>

              <Route path="/app/shifts">
                <Shifts userData={user} />
              </Route>

              <Route path="/app/designations">
                <Designation userData={user} />
              </Route>

              <Route path="/app/measurement-units">
                <MeasuringUnits userData={user} />
              </Route>

              <Route path="/app/employee">
                <Employees userData={user} />
              </Route>

              <Route path="/app/dispensers">
                <Dispenser userData={user} />
              </Route>

              <Route path="/app/uoms">
                <UOM userData={user} />
              </Route>

              <Route path="/app/item_groups">
                <ItemGroup userData={user} />
              </Route>

              <Route path="/app/items">
                <Item userData={user} />
              </Route>

              <Route path="/app/accounts">
                <Account userData={user} />
              </Route>

              <Redirect exact from="/app" to="/app/me" />
            </Switch>
          </div>

          <Box
            mt={5}
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent="center"
          >
            <Typography variant="body2" color="textSecondary" align="center">
              {"Copyright © "}
              <Link color="inherit" href="http://clonestudiobd.com/">
                Clone Studio
              </Link>{" "}
              {new Date().getFullYear()}
              {"."}
            </Typography>
          </Box>
        </div>
      </>
    </div>
  );

  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Layout);
