import axios from "axios";
import {
  EMPLOYEE_REQUEST,
  EMPLOYEE_SUCCESS,
  EMPLOYEE_FAIL,
  EMPLOYEE_CREATE_REQUEST,
  EMPLOYEE_CREATE_SUCCESS,
  EMPLOYEE_CREATE_FAIL,
  EMPLOYEE_CREATE_RESET,
  EMPLOYEE_UPDATE_REQUEST,
  EMPLOYEE_UPDATE_SUCCESS,
  EMPLOYEE_UPDATE_FAIL,
  EMPLOYEE_UPDATE_RESET,
  EMPLOYEE_DELETE_REQUEST,
  EMPLOYEE_DELETE_SUCCESS,
  EMPLOYEE_DELETE_FAIL,
  EMPLOYEE_PERMISSION_REQUEST,
  EMPLOYEE_PERMISSION_SUCCESS,
  EMPLOYEE_PERMISSION_FAIL,
  SHIFT_REQUEST,
  SHIFT_SUCCESS,
  SHIFT_FAIL,
  DESIGNATIONS_REQUEST,
  DESIGNATIONS_SUCCESS,
  DESIGNATIONS_FAIL,
} from "../constants/employeeConstant";

export const employeeList = () => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_REQUEST,
    });

    const { data } = await axios.get(
      "http://auto.clonestudiobd.com/api/employees"
    );

    dispatch({
      type: EMPLOYEE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const employeeCreateAction =
  (
    First_Name,
    last_name,
    fathers_name,
    monther_name,
    designation_id,
    shift_id,
    birth_date,
    join_date,
    phone_no,
    email_address,
    gross_salary,
    created_by,
    modified_by
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: EMPLOYEE_CREATE_REQUEST,
      });

      const config = {
        headers: {
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        "http://auto.clonestudiobd.com/api/employees",
        {
          First_Name,
          last_name,
          fathers_name,
          monther_name,
          designation_id,
          shift_id,
          birth_date,
          join_date,
          phone_no,
          email_address,
          gross_salary,
          created_by,
          modified_by,
        },
        config
      );

      console.log(
        "data4",

        First_Name,
        last_name,
        fathers_name,
        monther_name,
        designation_id,
        shift_id,
        birth_date,
        join_date,
        phone_no,
        email_address,
        gross_salary,
        created_by,
        modified_by
      );

      dispatch({
        type: EMPLOYEE_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EMPLOYEE_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const employeeUpdateAction =
  (
    id,
    First_Name,
    last_name,
    fathers_name,
    monther_name,
    designation_id,
    shift_id,
    birth_date,
    join_date,
    phone_no,
    email_address,
    gross_salary,
    created_by,
    modified_by
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: EMPLOYEE_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        `http://auto.clonestudiobd.com/api/employees/${id}`,
        {
          First_Name,
          last_name,
          fathers_name,
          monther_name,
          designation_id,
          shift_id,
          birth_date,
          join_date,
          phone_no,
          email_address,
          gross_salary,
          created_by,
          modified_by,
        },
        config
      );

      console.log(
        "update data",

        First_Name,
        last_name,
        fathers_name,
        monther_name,
        designation_id,
        shift_id,
        birth_date,
        join_date,
        phone_no,
        email_address,
        gross_salary,
        created_by,
        modified_by
      );

      dispatch({
        type: EMPLOYEE_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EMPLOYEE_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const employeeDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(
      `http://auto.clonestudiobd.com/api/employees/${id}`,
      config
    );

    dispatch({
      type: EMPLOYEE_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

//DESIGNATION
export const designationList = () => async (dispatch) => {
  try {
    dispatch({
      type: DESIGNATIONS_REQUEST,
    });

    const { data } = await axios.get(
      "http://auto.clonestudiobd.com/api/designations"
    );

    dispatch({
      type: DESIGNATIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DESIGNATIONS_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

//Shifts

export const shiftList = () => async (dispatch) => {
  try {
    dispatch({
      type: SHIFT_REQUEST,
    });

    const { data } = await axios.get(
      "http://auto.clonestudiobd.com/api/shifts"
    );

    dispatch({
      type: SHIFT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SHIFT_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// export const dimentionsPermissionAction =
//   (user_role_id, app_menu_id) => async (dispatch) => {
//     try {
//       dispatch({
//         type: EMPLOYEES_PERMISSION_REQUEST,
//       });

//       const config = {
//         headers: {
//           Accept: "application/json",
//         },
//       };

//       console.log("DataCheck", user_role_id, app_menu_id);

//       const { data } = await axios.post(
//         "http://bbox.clonestudiobd.com/api/userrolefunctions/permission",
//         {
//           user_role_id: user_role_id,
//           app_menu_id: app_menu_id,
//         },
//         config
//       );

//       console.log("DataP", user_role_id, app_menu_id);

//       dispatch({
//         type: EMPLOYEES_PERMISSION_SUCCESS,
//         payload: data,
//       });
//     } catch (error) {
//       dispatch({
//         type: EMPLOYEES_PERMISSION_FAIL,
//         payload:
//           error.response && error.response.data.error
//             ? error.response.data.error
//             : error.error,
//       });
//     }
//   };
