import {
  ITEM_GROUP_REQUEST,
  ITEM_GROUP_SUCCESS,
  ITEM_GROUP_FAIL,
  ITEM_GROUP_CREATE_REQUEST,
  ITEM_GROUP_CREATE_SUCCESS,
  ITEM_GROUP_CREATE_FAIL,
  ITEM_GROUP_CREATE_RESET,
  ITEM_GROUP_UPDATE_REQUEST,
  ITEM_GROUP_UPDATE_SUCCESS,
  ITEM_GROUP_UPDATE_FAIL,
  ITEM_GROUP_UPDATE_RESET,
  ITEM_GROUP_DELETE_REQUEST,
  ITEM_GROUP_DELETE_SUCCESS,
  ITEM_GROUP_DELETE_FAIL,
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAIL,
  ITEM_GROUP_PERMISSION_REQUEST,
  ITEM_GROUP_PERMISSION_SUCCESS,
  ITEM_GROUP_PERMISSION_FAIL,
} from "../constants/groupConstant";

export const groupReducer = (state = { groups: [] }, action) => {
  switch (action.type) {
    case ITEM_GROUP_REQUEST:
      return { loading: true, groups: [] };
    case ITEM_GROUP_SUCCESS:
      return { loading: false, groups: action.payload };
    case ITEM_GROUP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const groupCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ITEM_GROUP_CREATE_REQUEST:
      return { loading: true };
    case ITEM_GROUP_CREATE_SUCCESS:
      return { loading: false, groupCreate: action.payload };
    case ITEM_GROUP_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ITEM_GROUP_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const groupUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ITEM_GROUP_UPDATE_REQUEST:
      return { loading: true };
    case ITEM_GROUP_UPDATE_SUCCESS:
      return { loading: false, groupUpdate: action.payload };
    case ITEM_GROUP_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ITEM_GROUP_UPDATE_RESET:
      return { groupCreate: {} };
    default:
      return state;
  }
};

export const groupDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ITEM_GROUP_DELETE_REQUEST:
      return { loading: true };
    case ITEM_GROUP_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ITEM_GROUP_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//USER
export const userReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_REQUEST:
      return { loading: true, users: [] };
    case USER_SUCCESS:
      return { loading: false, users: action.payload };
    case USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// export const productPermissionReducer = (
//   state = { departmentPermission: [] },
//   action
// ) => {
//   switch (action.type) {
//     case PRODUCT_PERMISSION_REQUEST:
//       return { loading: true, departmentPermission: [] };
//     case PRODUCT_PERMISSION_SUCCESS:
//       return { loading: false, departmentPermission: action.payload };
//     case PRODUCT_PERMISSION_FAIL:
//       return { loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };
