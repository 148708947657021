import React, { useState, useEffect } from "react";

export function CreateValidation(validateOnChange = false, validate) {
  const [values, setValues] = useState({
    user_id: "",
    account_type: "",
    account_name: "",
    opening_balance: "",
    address: "",
    mobile_no: "",
    email_address: "",
    photo: "",
    remarks: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  console.log("bbb", values);

  const resetForm = () => {
    setValues(values);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}

export function EditValidation(item, validateOnChange = false, validate) {
  const [values, setValues] = useState({
    user_id: item ? item.user_id : "",
    account_type: item ? item.account_type : "",
    account_name: item ? item.account_name : "",
    opening_balance: item ? item.opening_balance : "",
    address: item ? item.address : "",
    mobile_no: item ? item.mobile_no : "",
    email_address: item ? item.email_address : "",
    photo: item ? item.photo : "",
    remarks: item ? item.remarks : "",
  });
  const [errors, setErrors] = useState({});

  console.log("val", values);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  useEffect(() => {
    setValues({
      user_id: item.user_id,
      account_type: item.account_type,
      account_name: item.account_name,
      opening_balance: item.opening_balance,
      address: item.address,
      mobile_no: item.mobile_no,
      email_address: item.email_address,
      photo: item.photo,
      remarks: item.remarks,
    });
  }, [item]);

  console.log("bbb", values);

  const resetForm = () => {
    setValues(values);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}
