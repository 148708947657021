import {
  DISPENSERS_REQUEST,
  DISPENSERS_SUCCESS,
  DISPENSERS_FAIL,
  DISPENSERS_CREATE_REQUEST,
  DISPENSERS_CREATE_SUCCESS,
  DISPENSERS_CREATE_FAIL,
  DISPENSERS_CREATE_RESET,
  DISPENSERS_UPDATE_REQUEST,
  DISPENSERS_UPDATE_SUCCESS,
  DISPENSERS_UPDATE_FAIL,
  DISPENSERS_UPDATE_RESET,
  DISPENSERS_DELETE_REQUEST,
  DISPENSERS_DELETE_SUCCESS,
  DISPENSERS_DELETE_FAIL,
  DISPENSERS_PERMISSION_REQUEST,
  DISPENSERS_PERMISSION_SUCCESS,
  DISPENSERS_PERMISSION_FAIL,
  PRODUCT_REQUEST,
  PRODUCT_SUCCESS,
  PRODUCT_FAIL,
} from "../constants/dispenserConstant";

export const dispensersReducer = (state = { dispensers : [] }, action) => {
  switch (action.type) {
    case DISPENSERS_REQUEST:
      return { loading: true, dispensers : [] };
    case DISPENSERS_SUCCESS:
      return { loading: false, dispensers : action.payload };
    case DISPENSERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const dispensersCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case DISPENSERS_CREATE_REQUEST:
      return { loading: true };
    case DISPENSERS_CREATE_SUCCESS:
      return { loading: false, dispensersCreate: action.payload };
    case DISPENSERS_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case DISPENSERS_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const dispensersUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case DISPENSERS_UPDATE_REQUEST:
      return { loading: true };
    case DISPENSERS_UPDATE_SUCCESS:
      return { loading: false, dispensersUpdate: action.payload };
    case DISPENSERS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case DISPENSERS_UPDATE_RESET:
      return { dispensersCreate: {} };
    default:
      return state;
  }
};

export const dispensersDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DISPENSERS_DELETE_REQUEST:
      return { loading: true };
    case DISPENSERS_DELETE_SUCCESS:
      return { loading: false, success: true };
    case DISPENSERS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//PRODUCT
export const productReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_REQUEST:
      return { loading: true, products: [] };
    case PRODUCT_SUCCESS:
      return { loading: false, products: action.payload };
    case PRODUCT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// export const dimentionsPermissionReducer = (
//   state = { departmentPermission: [] },
//   action
// ) => {
//   switch (action.type) {
//     case EMPLOYEES_PERMISSION_REQUEST:
//       return { loading: true, departmentPermission: [] };
//     case EMPLOYEES_PERMISSION_SUCCESS:
//       return { loading: false, departmentPermission: action.payload };
//     case EMPLOYEES_PERMISSION_FAIL:
//       return { loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };
