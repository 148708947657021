import axios from "axios";
import {
  FUEL_TANK_REQUEST,
  FUEL_TANK_SUCCESS,
  FUEL_TANK_FAIL,
  FUEL_TANK_CREATE_REQUEST,
  FUEL_TANK_CREATE_SUCCESS,
  FUEL_TANK_CREATE_FAIL,
  FUEL_TANK_CREATE_RESET,
  FUEL_TANK_UPDATE_REQUEST,
  FUEL_TANK_UPDATE_SUCCESS,
  FUEL_TANK_UPDATE_FAIL,
  FUEL_TANK_UPDATE_RESET,
  FUEL_TANK_DELETE_REQUEST,
  FUEL_TANK_DELETE_SUCCESS,
  FUEL_TANK_DELETE_FAIL,
  FUEL_TANK_PERMISSION_REQUEST,
  FUEL_TANK_PERMISSION_SUCCESS,
  FUEL_TANK_PERMISSION_FAIL,
  PRODUCT_REQUEST,
  PRODUCT_SUCCESS,
  PRODUCT_FAIL,
} from "../constants/fueltankConstant";

export const fueltankList = () => async (dispatch) => {
  try {
    dispatch({
      type: FUEL_TANK_REQUEST,
    });

    const { data } = await axios.get(
      "http://auto.clonestudiobd.com/api/fuel-tanks"
    );

    dispatch({
      type: FUEL_TANK_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FUEL_TANK_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const fueltankCreateAction =
  (
    tank_name,
    alias,
    capacity,
    minimum_stock,
    product_id,
    created_by,
    modified_by
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: FUEL_TANK_CREATE_REQUEST,
      });

      const config = {
        headers: {
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        "http://auto.clonestudiobd.com/api/fuel-tanks",
        {
          tank_name,
          alias,
          capacity,
          minimum_stock,
          product_id,
          created_by,
          modified_by,
        },
        config
      );

      console.log(
        "data4",

        tank_name,
        alias,
        capacity,
        minimum_stock,
        product_id,
        created_by,
        modified_by
      );

      dispatch({
        type: FUEL_TANK_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FUEL_TANK_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const fueltankUpdateAction =
  (
    id,
    tank_name,
    alias,
    capacity,
    minimum_stock,
    product_id,
    created_by,
    modified_by
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: FUEL_TANK_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        `http://auto.clonestudiobd.com/api/fuel-tanks/${id}`,
        {
          tank_name,
          alias,
          capacity,
          minimum_stock,
          product_id,
          created_by,
          modified_by,
        },
        config
      );

      console.log(
        "update data",

        tank_name,
        alias,
        capacity,
        minimum_stock,
        product_id,
        created_by,
        modified_by
      );

      dispatch({
        type: FUEL_TANK_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FUEL_TANK_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const fueltankDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: FUEL_TANK_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(
      `http://auto.clonestudiobd.com/api/fuel-tanks/${id}`,
      config
    );

    dispatch({
      type: FUEL_TANK_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: FUEL_TANK_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

//PRODUCTS

export const productList = () => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_REQUEST,
    });

    const { data } = await axios.get(
      "http://auto.clonestudiobd.com/api/products"
    );

    dispatch({
      type: PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// export const dimentionsPermissionAction =
//   (user_role_id, app_menu_id) => async (dispatch) => {
//     try {
//       dispatch({
//         type: EMPLOYEES_PERMISSION_REQUEST,
//       });

//       const config = {
//         headers: {
//           Accept: "application/json",
//         },
//       };

//       console.log("DataCheck", user_role_id, app_menu_id);

//       const { data } = await axios.post(
//         "http://bbox.clonestudiobd.com/api/userrolefunctions/permission",
//         {
//           user_role_id: user_role_id,
//           app_menu_id: app_menu_id,
//         },
//         config
//       );

//       console.log("DataP", user_role_id, app_menu_id);

//       dispatch({
//         type: EMPLOYEES_PERMISSION_SUCCESS,
//         payload: data,
//       });
//     } catch (error) {
//       dispatch({
//         type: EMPLOYEES_PERMISSION_FAIL,
//         payload:
//           error.response && error.response.data.error
//             ? error.response.data.error
//             : error.error,
//       });
//     }
//   };
