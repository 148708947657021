import React, { useState, useEffect } from "react";

export function CreateValidation(validateOnChange = false, validate) {
  const [values, setValues] = useState({
    user_id: "",
    item_group_id: "",
    item_name: "",
    uom: "",
    purchase_value: "",
    sale_value: "",
    min_stock: "",
    max_stock: "",
    discount_Per: "",
    vat_per: "",
    opening_stock: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  console.log("bbb", values);

  const resetForm = () => {
    setValues(values);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}

export function EditValidation(item, validateOnChange = false, validate) {
  const [values, setValues] = useState({
    user_id: item ? item.user_id : "",
    item_group_id: item ? item.item_group_id : "",
    item_name: item ? item.item_name : "",
    uom: item ? item.uom : "",
    purchase_value: item ? item.purchase_value : "",
    sale_value: item ? item.sale_value : "",
    min_stock: item ? item.min_stock : "",
    max_stock: item ? item.max_stock : "",
    discount_Per: item ? item.discount_Per : "",
    vat_per: item ? item.vat_per : "",
    opening_stock: item ? item.opening_stock : "",

    // print_serial: item ? item.print_serial : "",
  });
  const [errors, setErrors] = useState({});

  console.log("val", values);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  useEffect(() => {
    setValues({
      user_id: item.user_id,
      item_group_id: item.item_group_id,
      item_name: item.item_name,
      uom: item.uom,
      purchase_value: item.purchase_value,
      sale_value: item.sale_value,
      min_stock: item.min_stock,
      max_stock: item.max_stock,
      discount_Per: item.discount_Per,
      vat_per: item.vat_per,
      opening_stock: item.opening_stock,

      // print_serial: item.print_serial,
    });
  }, [item]);

  console.log("bbb", values);

  const resetForm = () => {
    setValues(values);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}
