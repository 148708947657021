import axios from "axios";
import {
  PERMISSION_REQUEST,
  PERMISSION_SUCCESS,
  PERMISSION_FAIL,
} from "../constants/permissionConstants";

export const permissionAction = (user, app_menu_id) => async (dispatch) => {
  try {
    dispatch({
      type: PERMISSION_REQUEST,
    });

    console.log("hhh", user, app_menu_id);

    const user_role_id = user.user_role_id;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.post(
      "http://auto.clonestudiobd.com/api/userrolefunctions/permission",
      { user_role_id, app_menu_id },
      config
    );

    dispatch({
      type: PERMISSION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PERMISSION_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
