import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    item,
    value,
    handleInputChange,
    measurementState,
    setUomId,
    error,
    helperText,
  } = props;

  console.log("edit data", item);

  var uomName = item
    ? measurementState
        .filter((o1) => {
          return o1.id === item.uom_id;
        })
        .map((item) => item.uom)
    : null;

  // const handleChange = (event) => {
  //   setRoleStatus(event.target.value);
  // };

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="product_name"
              variant="outlined"
              required
              fullWidth
              id="product_name"
              label="Product Name"
              autoFocus
              size="small"
              defaultValue={item ? item.product_name : null}
              error={error.product_name}
              helperText={helperText.product_name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                Uom *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setUomId(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? uomName : "None"}</option>
                {measurementState
                  ? measurementState.map((item) => (
                      <option value={item.id}>{item.uom}</option>
                    ))
                  : null}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="purchase_rate"
              variant="outlined"
              fullWidth
              id="purchase_rate"
              label="Purchase Rate"
              autoFocus
              size="small"
              defaultValue={item ? item.purchase_rate : null}
              value={value.purchase_rate}
              error={error.purchase_rate}
              helperText={helperText.purchase_rate}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="sale_rate"
              variant="outlined"
              fullWidth
              id="sale_rate"
              label="Sale Rate"
              autoFocus
              size="small"
              defaultValue={item ? item.sale_rate : null}
              value={value.sale_rate}
              error={error.sale_rate}
              helperText={helperText.sale_rate}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
