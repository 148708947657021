import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import {
  lighten,
  makeStyles,
  withStyles,
  useTheme,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import indigo from "@material-ui/core/colors/indigo";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeletePopup from "./DeletePopup";
import {
  userRoleList,
  roleFunctionList,
  roleFunctionDeleteAction,
  roleFunctionCreateAction,
  roleFunctionBulkDeleteAction,
  roleFunctionPermissionAction,
} from "../../../redux/actions/userRoleActions";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import InfoIcon from "@material-ui/icons/Info";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import { Grid } from "@material-ui/core";
import Notification from "./Notification";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#990012",
    color: "#fff",
    fontSize: 12,
    fontWeight: "bold",
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator, searchValues) {
  const stabilizedThis = searchValues.fn(array).map((el, id) => [el, id]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "role_name",
    numeric: false,
    disablePadding: true,
    label: "User Role",
    sortable: true,
  },
  {
    id: "menu_caption",
    numeric: true,
    disablePadding: false,
    label: "App Menu",
    sortable: true,
  },
  {
    id: "permissions",
    numeric: true,
    disablePadding: false,
    label: "Permissions",
    sortable: false,
  },
  {
    id: 4,
    numeric: true,
    disablePadding: false,
    label: "Actions",
    sortable: false,
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
            size="small"
          />
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align="center"
            sortDirection={
              orderBy === headCell.id && headCell.sortable === true
                ? order
                : false
            }
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {/* {order === "desc" ? "sorted descending" : "sorted ascending"} */}
                    {order === "desc" ? "" : ""}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              <span>{headCell.label}</span>
            )}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    searchBox,
    SearchClose,
    SearchOpen,
    setOpenPopup,
    handleChange,
    searchValues,
    setSearchValues,
    handleSearch,
    setAddNew,
    onRowAdd,
    handleBulkDelete,
    insertPermission,
    printPermission,
  } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <div style={{ flexGrow: 1 }}>
          <TextField
            label="Search"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </div>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={handleBulkDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <div style={{ display: "flex", flexDirection: "row" }}>
          {/* <Tooltip title='Search'>
            <IconButton aria-label='Search' onClick={SearchOpen}>
              <SearchIcon />
            </IconButton>
          </Tooltip> */}
          <Tooltip title="Add New Row">
            <IconButton
              aria-label="Add New Row"
              onClick={onRowAdd}
              disabled={!insertPermission}
            >
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download">
            <IconButton aria-label="Download" disabled={!printPermission}>
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = (theme) => ({
  root: {
    width: "100%",
    fontSize: "0.1em",
  },
  table: {
    minWidth: 750,
  },
  tableBody: {
    minHeight: 400,
  },
  tableCell: {
    fontSize: "0.1em",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flex: "1 1 100%",
  },
});

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const EnhancedTable = (props) => {
  const { roleFunctions, roles, modules, permissions } = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchBox, setSearchBox] = React.useState(false);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [openEditPopup, setOpenEditPopup] = React.useState(false);
  const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
  const [item, setItem] = React.useState("");
  const [rowdata, setRowData] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [userRoleFunctions, setUserRoleFunctions] = React.useState([]);
  const [roleFunctionPush, setRoleFunctionPush] = React.useState({
    data: "",
  });
  const [toggleButton, setToggleButton] = React.useState(false);

  const [checked, setChecked] = React.useState({
    insert: false,
    update: false,
    delete: false,
    print: false,
  });

  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  // const handleCheckBoxChange = (evt) => {
  //   setChecked({
  //     ...checked,
  //     [evt.target.name]: evt.target.checked,
  //   });
  // };

  // const handle1Change = (event) => {
  //   setChecked({
  //     insert: event.target.value,
  //   });
  // };

  // console.log('checked', checked);

  const handleAddEvent = () => {
    var id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
    var product = {
      id: id,
      user_role_id: "",
      app_menu_id: "",
      user_role_function_insert: false,
      user_role_function_update: false,
      user_role_function_delete: false,
      user_role_function_print: false,
    };

    setProducts((currentArray) => [...currentArray, product]);
  };

  const newArray = (roleFunctions) => {
    let data = roleFunctions.map((e) => {
      if (
        e.user_role_function_insert === 0 &&
        e.user_role_function_update === 0 &&
        e.user_role_function_delete === 0 &&
        e.user_role_function_print === 0
      ) {
        e.user_role_function_insert = false;
        e.user_role_function_update = false;
        e.user_role_function_delete = false;
        e.user_role_function_print = false;
      } else if (
        e.user_role_function_insert === 0 &&
        e.user_role_function_update === 0 &&
        e.user_role_function_delete === 0 &&
        e.user_role_function_print === 1
      ) {
        e.user_role_function_insert = false;
        e.user_role_function_update = false;
        e.user_role_function_delete = false;
        e.user_role_function_print = true;
      } else if (
        e.user_role_function_insert === 0 &&
        e.user_role_function_update === 0 &&
        e.user_role_function_delete === 1 &&
        e.user_role_function_print === 0
      ) {
        e.user_role_function_insert = false;
        e.user_role_function_update = false;
        e.user_role_function_delete = true;
        e.user_role_function_print = false;
      } else if (
        e.user_role_function_insert === 0 &&
        e.user_role_function_update === 0 &&
        e.user_role_function_delete === 1 &&
        e.user_role_function_print === 1
      ) {
        e.user_role_function_insert = false;
        e.user_role_function_update = false;
        e.user_role_function_delete = true;
        e.user_role_function_print = true;
      } else if (
        e.user_role_function_insert === 0 &&
        e.user_role_function_update === 1 &&
        e.user_role_function_delete === 0 &&
        e.user_role_function_print === 0
      ) {
        e.user_role_function_insert = false;
        e.user_role_function_update = true;
        e.user_role_function_delete = false;
        e.user_role_function_print = true;
      } else if (
        e.user_role_function_insert === 0 &&
        e.user_role_function_update === 1 &&
        e.user_role_function_delete === 0 &&
        e.user_role_function_print === 1
      ) {
        e.user_role_function_insert = false;
        e.user_role_function_update = true;
        e.user_role_function_delete = false;
        e.user_role_function_print = true;
      } else if (
        e.user_role_function_insert === 0 &&
        e.user_role_function_update === 1 &&
        e.user_role_function_delete === 1 &&
        e.user_role_function_print === 0
      ) {
        e.user_role_function_insert = false;
        e.user_role_function_update = true;
        e.user_role_function_delete = true;
        e.user_role_function_print = true;
      } else if (
        e.user_role_function_insert === 0 &&
        e.user_role_function_update === 1 &&
        e.user_role_function_delete === 1 &&
        e.user_role_function_print === 1
      ) {
        e.user_role_function_insert = false;
        e.user_role_function_update = true;
        e.user_role_function_delete = true;
        e.user_role_function_print = true;
      } else if (
        e.user_role_function_insert === 1 &&
        e.user_role_function_update === 0 &&
        e.user_role_function_delete === 0 &&
        e.user_role_function_print === 0
      ) {
        e.user_role_function_insert = true;
        e.user_role_function_update = false;
        e.user_role_function_delete = false;
        e.user_role_function_print = false;
      } else if (
        e.user_role_function_insert === 1 &&
        e.user_role_function_update === 0 &&
        e.user_role_function_delete === 0 &&
        e.user_role_function_print === 1
      ) {
        e.user_role_function_insert = true;
        e.user_role_function_update = false;
        e.user_role_function_delete = false;
        e.user_role_function_print = true;
      } else if (
        e.user_role_function_insert === 1 &&
        e.user_role_function_update === 0 &&
        e.user_role_function_delete === 1 &&
        e.user_role_function_print === 0
      ) {
        e.user_role_function_insert = true;
        e.user_role_function_update = false;
        e.user_role_function_delete = true;
        e.user_role_function_print = false;
      } else if (
        e.user_role_function_insert === 1 &&
        e.user_role_function_update === 0 &&
        e.user_role_function_delete === 1 &&
        e.user_role_function_print === 1
      ) {
        e.user_role_function_insert = true;
        e.user_role_function_update = false;
        e.user_role_function_delete = true;
        e.user_role_function_print = true;
      } else if (
        e.user_role_function_insert === 1 &&
        e.user_role_function_update === 1 &&
        e.user_role_function_delete === 0 &&
        e.user_role_function_print === 0
      ) {
        e.user_role_function_insert = true;
        e.user_role_function_update = true;
        e.user_role_function_delete = false;
        e.user_role_function_print = false;
      } else if (
        e.user_role_function_insert === 1 &&
        e.user_role_function_update === 1 &&
        e.user_role_function_delete === 0 &&
        e.user_role_function_print === 1
      ) {
        e.user_role_function_insert = true;
        e.user_role_function_update = true;
        e.user_role_function_delete = false;
        e.user_role_function_print = true;
      } else if (
        e.user_role_function_insert === 1 &&
        e.user_role_function_update === 1 &&
        e.user_role_function_delete === 1 &&
        e.user_role_function_print === 0
      ) {
        e.user_role_function_insert = true;
        e.user_role_function_update = true;
        e.user_role_function_delete = true;
        e.user_role_function_print = false;
      } else if (
        e.user_role_function_insert === 1 &&
        e.user_role_function_update === 1 &&
        e.user_role_function_delete === 1 &&
        e.user_role_function_print === 1
      ) {
        e.user_role_function_insert = true;
        e.user_role_function_update = true;
        e.user_role_function_delete = true;
        e.user_role_function_print = true;
      }
      return e;
    });
    setUserRoleFunctions(data);

    // roleFunctions.forEach(function (v) {
    //   if (v.crud_value.includes('IUDP')) {
    //     v.insert = true;
    //     v.print = true;
    //     v.update = true;
    //     v.delete = true;
    //   } else if (v.crud_value.includes('P')) {
    //     v.print = true;
    //   } else if (v.crud_value.includes('U')) {
    //     v.update = true;
    //   } else if (v.crud_value.includes('D')) {
    //     v.delete = true;
    //   }
    // }),
  };

  const handleAddProductTable = (evt) => {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };

    var value = JSON.parse(item.value.toLowerCase());
    console.log("iddd5", item);

    var newProducts = products.map(function (product) {
      for (var key in product) {
        console.log("prr", products);
        if (key === item.name && product.id === item.id) {
          product[key] = value;
        }
      }

      return product;
    });
    setProducts(newProducts);
    var marge = products.concat(userRoleFunctions);
    setToggleButton(true);
    setRoleFunctionPush({
      data: marge,
    });
    console.log("xyx", marge);
  };

  const handleProductTable = (evt) => {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };

    var id = parseInt(item.id, 10);
    var value = JSON.parse(item.value.toLowerCase());

    var newProducts = userRoleFunctions.map((product) => {
      console.log("j999", product);
      for (var key in product) {
        if (key === item.name && product.id === id) {
          product[key] = value;
          // product['crud_value'] =
          //   product.insert + product.update + product.delete + product.print;
        }
      }
      return product;
    });
    setUserRoleFunctions(newProducts);
    setToggleButton(true);
    var marge = userRoleFunctions.concat(products);
    setRoleFunctionPush({
      data: marge,
    });
    console.log("xyx12", marge);
  };

  const handleRowDel = (product) => {
    var index = products.indexOf(product);
    products.splice(index, 1);
    console.log("pro", products);
    setProducts([...products]);
  };

  const [searchValues, setSearchValues] = React.useState({
    fn: (userRoleFunctions) => {
      return userRoleFunctions;
    },
  });

  const [role_status, setRoleStatus] = React.useState("A");

  const [id, setId] = React.useState("");

  console.log("item", id);

  const handleChange = (event) => {
    setRoleStatus(event.target.value);
  };

  const dispatch = useDispatch();

  var cloneArray = JSON.parse(JSON.stringify(roleFunctions));

  console.log("abcd", roleFunctions);

  useEffect(() => {
    newArray(roleFunctions);
  }, [dispatch, roleFunctions]);

  console.log("yyy", products);

  console.log("jhj", userRoleFunctions);
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(roleFunctionCreateAction(JSON.stringify(roleFunctionPush)));
    setTimeout(() => {
      dispatch(roleFunctionList());
      setToggleButton(false);
      setProducts([]);
    }, 2000);
  };

  const cancelHandler = (e) => {
    setProducts([]);
    setToggleButton(false);
  };

  const handleBulkDelete = () => {
    dispatch(roleFunctionBulkDeleteAction(selected));
    setTimeout(() => {
      dispatch(roleFunctionList());
      setSelected([]);
    }, 2000);
  };

  const SearchOpen = () => {
    setSearchBox(true);
  };

  const handleSearch = (e) => {
    let target = e.target;
    setSearchValues({
      fn: (userRoleFunctions) => {
        if (target.value == "") return userRoleFunctions;
        else
          return userRoleFunctions.filter(
            (x) =>
              x.role_name.toLowerCase().includes(target.value) ||
              x.menu_caption.toLowerCase().includes(target.value)
          );
      },
    });
  };

  const SearchClose = (value) => {
    setSearchBox(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userRoleFunctions.map((n) => n.id);
      setSelected(newSelecteds);
      setSearchBox(false);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setSearchBox(false);
  };

  console.log("selected", selected);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const onAddRowClick = () => {
    setRowData(
      rowdata.concat({ username: "", email: "", gender: "", phone: "" })
    );
  };

  // const handleChange = (prop) => (event) => {
  //   setSearchValues({ ...searchValues, [prop]: event.target.value });
  // };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      userRoleFunctions ? userRoleFunctions.length : null - page * rowsPerPage
    );

  //permissions

  const insertPermission = permissions
    ? permissions.user_role_function_insert === 1
      ? true
      : false
    : null;
  console.log("Insert", insertPermission);

  const updatePermission = permissions
    ? permissions.user_role_function_update === 1
      ? true
      : false
    : null;
  console.log("Update", updatePermission);

  const deletePermission = permissions
    ? permissions.user_role_function_delete === 1
      ? true
      : false
    : null;
  console.log("Delete", deletePermission);

  const printPermission = permissions
    ? permissions.user_role_function_print === 1
      ? true
      : false
    : null;
  console.log("Print", printPermission);

  return (
    <div className={classes.root}>
      <div style={{ display: "flex" }}>
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
          style={{ padding: 10, flexGrow: 1 }}
        >
          User Role Function List
        </Typography>
        {/* <Button
          variant='outlined'
          color='primary'
          size='small'
          style={{ width: 100, height: 30, marginTop: 10, fontSize: 12 }}
          startIcon={<AddIcon />}
          onClick={() => setOpenPopup(true)}
        >
          Add New
        </Button> */}
      </div>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          SearchOpen={SearchOpen}
          SearchClose={SearchClose}
          searchBox={searchBox}
          setOpenPopup={setOpenPopup}
          searchValues={searchValues}
          setSearchValues={setSearchValues}
          handleSearch={handleSearch}
          onRowAdd={() => handleAddEvent()}
          handleBulkDelete={handleBulkDelete}
          insertPermission={insertPermission}
          printPermission={printPermission}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
            size="small"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={userRoleFunctions ? userRoleFunctions.length : null}
            />
            <TableBody>
              {products
                ? products.map((product) => {
                    return (
                      <TableRow hover style={{ backgroundColor: "#EAEAEA" }}>
                        <TableCell
                          padding="checkbox"
                          className={classes.tableCell}
                        ></TableCell>
                        <TableCell
                          align="center"
                          padding="checkbox"
                          style={{ width: "27%" }}
                        >
                          <FormControl
                            margin="dense"
                            size="small"
                            fullWidth="true"
                            variant="outlined"
                          >
                            <Select
                              native
                              style={{ fontSize: 12 }}
                              name={"user_role_id"}
                              id={product.id}
                              value={product.user_role_id}
                              onChange={(evt) => handleAddProductTable(evt)}
                            >
                              <option value="">None</option>
                              {roles
                                ? roles.map((item) =>
                                    item.role_status === "A" ? (
                                      <option value={item.id}>
                                        {item.role_name}
                                      </option>
                                    ) : null
                                  )
                                : null}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ width: "27%" }}
                          padding="checkbox"
                        >
                          <FormControl
                            margin="dense"
                            size="small"
                            fullWidth="true"
                            variant="outlined"
                          >
                            <Select
                              native
                              style={{ fontSize: 12 }}
                              name={"app_menu_id"}
                              id={product.id}
                              value={product.app_menu_id}
                              onChange={(evt) => handleAddProductTable(evt)}
                            >
                              <option value="">None</option>
                              <optgroup label="Dashboard">
                                {modules
                                  ? modules
                                      .filter((item) => item.parent_menu === 3)
                                      .map((item) => (
                                        <option value={item.id}>
                                          {item.menu_caption}
                                        </option>
                                      ))
                                  : null}
                              </optgroup>
                              <optgroup label="Elements">
                                {modules
                                  ? modules
                                      .filter((item) => item.parent_menu === 4)
                                      .map((item) => (
                                        <option value={item.id}>
                                          {item.menu_caption}
                                        </option>
                                      ))
                                  : null}
                              </optgroup>
                              <optgroup label="Jobs">
                                {modules
                                  ? modules
                                      .filter((item) => item.parent_menu === 5)
                                      .map((item) => (
                                        <option value={item.id}>
                                          {item.menu_caption}
                                        </option>
                                      ))
                                  : null}
                              </optgroup>
                              <optgroup label="Settings">
                                {modules
                                  ? modules
                                      .filter((item) => item.parent_menu === 6)
                                      .map((item) => (
                                        <option value={item.id}>
                                          {item.menu_caption}
                                        </option>
                                      ))
                                  : null}
                              </optgroup>
                              <optgroup label="Settings">
                                {modules
                                  ? modules
                                      .filter((item) => item.parent_menu === 43)
                                      .map((item) => (
                                        <option value={item.id}>
                                          {item.menu_caption}
                                        </option>
                                      ))
                                  : null}
                              </optgroup>
                              {/* {modules
                              ? modules.map((item) => (
                                  <option value={item.id}>
                                    {item.menu_caption}
                                  </option>
                                ))
                              : null} */}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            fontSize: 12,
                            width: "50%",
                          }}
                          padding="checkbox"
                        >
                          <FormControlLabel
                            value={!product.user_role_function_insert}
                            control={
                              <Checkbox
                                name={"user_role_function_insert"}
                                id={product.id}
                                checked={product.user_role_function_insert}
                                size="small"
                                onChange={(evt) => {
                                  handleAddProductTable(evt);
                                }}
                              />
                            }
                            label={<span style={{ fontSize: 12 }}>Insert</span>}
                          />
                          <FormControlLabel
                            value={!product.user_role_function_update}
                            control={
                              <Checkbox
                                name={"user_role_function_update"}
                                id={product.id}
                                checked={product.user_role_function_update}
                                size="small"
                                onChange={(evt) => {
                                  handleAddProductTable(evt);
                                }}
                              />
                            }
                            label={<span style={{ fontSize: 12 }}>Update</span>}
                          />
                          <FormControlLabel
                            value={!product.user_role_function_delete}
                            control={
                              <Checkbox
                                name={"user_role_function_delete"}
                                id={product.id}
                                checked={product.user_role_function_delete}
                                size="small"
                                onChange={(evt) => {
                                  handleAddProductTable(evt);
                                }}
                              />
                            }
                            label={<span style={{ fontSize: 12 }}>Delete</span>}
                          />
                          <FormControlLabel
                            value={!product.user_role_function_print}
                            control={
                              <Checkbox
                                name={"user_role_function_print"}
                                id={product.id}
                                checked={product.user_role_function_print}
                                size="small"
                                onChange={(evt) => {
                                  handleAddProductTable(evt);
                                }}
                              />
                            }
                            label={<span style={{ fontSize: 12 }}>Print</span>}
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: 12,
                          }}
                          padding="checkbox"
                        >
                          <Tooltip title="Delete">
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={(product) => {
                                // setId(row.id);
                                // setOpenDeletePopup(true);
                                // setItem(row);
                                handleRowDel(product);
                              }}
                            >
                              <DeleteIcon
                                fontSize="small"
                                style={{ fill: "red" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null}
              {userRoleFunctions && userRoleFunctions.length !== 0 ? (
                stableSort(
                  userRoleFunctions,
                  getComparator(order, orderBy),
                  searchValues
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, id) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${id}`;

                    var roleName = roles
                      .filter((item) => {
                        return item.id === row.user_role_id;
                      })
                      .map((item) => item.role_name);

                    var appName = modules
                      .filter((item) => {
                        return item.id === row.app_menu_id;
                      })
                      .map((item) => item.menu_caption);

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell
                          onClick={(event) => handleClick(event, row.id)}
                          padding="checkbox"
                          className={classes.tableCell}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            size="small"
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          padding="checkbox"
                          style={{ width: "27%" }}
                        >
                          <FormControl
                            margin="dense"
                            size="small"
                            fullWidth="true"
                            variant="outlined"
                          >
                            {/* <InputLabel style={{ fontSize: 12 }}>
                            User Role
                          </InputLabel> */}
                            <Select
                              native
                              style={{ fontSize: 12 }}
                              inputProps={{ readOnly: true }}
                              name="user_role_id"
                            >
                              <option value="">{row.role_name}</option>
                              {/* {roles
                              ? roles.map((item) => (
                                  <option value={item.id}>
                                    {item.role_name}
                                  </option>
                                ))
                              : null} */}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ width: "27%" }}
                          padding="checkbox"
                        >
                          <FormControl
                            margin="dense"
                            size="small"
                            fullWidth="true"
                            variant="outlined"
                          >
                            <Select
                              native
                              style={{ fontSize: 12 }}
                              name="app_menu_id"
                            >
                              <option value="">{row.menu_caption}</option>
                              {/* <optgroup label='HR'>
                              {modules
                                ? modules
                                    .filter((item) => item.parent_menu === 11)
                                    .map((item) => (
                                      <option value={item.id}>
                                        {item.menu_caption}
                                      </option>
                                    ))
                                : null}
                            </optgroup>
                            <optgroup label='Inventory'>
                              {modules
                                ? modules
                                    .filter((item) => item.parent_menu === 18)
                                    .map((item) => (
                                      <option value={item.id}>
                                        {item.menu_caption}
                                      </option>
                                    ))
                                : null}
                            </optgroup>
                            <optgroup label='Inventory'>
                              {modules
                                ? modules
                                    .filter((item) => item.parent_menu === 18)
                                    .map((item) => (
                                      <option value={item.id}>
                                        {item.menu_caption}
                                      </option>
                                    ))
                                : null}
                            </optgroup>
                            <optgroup label='Accounts'>
                              {modules
                                ? modules
                                    .filter((item) => item.parent_menu === 22)
                                    .map((item) => (
                                      <option value={item.id}>
                                        {item.menu_caption}
                                      </option>
                                    ))
                                : null}
                            </optgroup>
                            <optgroup label='Settings'>
                              {modules
                                ? modules
                                    .filter((item) => item.parent_menu === 30)
                                    .map((item) => (
                                      <option value={item.id}>
                                        {item.menu_caption}
                                      </option>
                                    ))
                                : null}
                            </optgroup>
                            {/* {modules */}
                              {/* ? modules.map((item) => (
                                  <option value={item.id}>
                                    {item.menu_caption}
                                  </option>
                                ))
                              // : null} */}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            fontSize: 12,
                            width: "50%",
                          }}
                          padding="checkbox"
                        >
                          <FormControlLabel
                            value={!row.user_role_function_insert}
                            control={
                              <Checkbox
                                checked={row.user_role_function_insert}
                                id={row.id}
                                onChange={(evt) => {
                                  handleProductTable(evt);
                                }}
                                name="user_role_function_insert"
                                size="small"
                                disabled={!updatePermission}
                              />
                            }
                            label={<span style={{ fontSize: 12 }}>Insert</span>}
                          />
                          <FormControlLabel
                            value={!row.user_role_function_update}
                            control={
                              <Checkbox
                                checked={row.user_role_function_update}
                                id={row.id}
                                onChange={(evt) => {
                                  handleProductTable(evt);
                                }}
                                name="user_role_function_update"
                                size="small"
                                disabled={!updatePermission}
                              />
                            }
                            label={<span style={{ fontSize: 12 }}>Update</span>}
                          />
                          <FormControlLabel
                            value={!row.user_role_function_delete}
                            control={
                              <Checkbox
                                checked={row.user_role_function_delete}
                                id={row.id}
                                onChange={(evt) => {
                                  handleProductTable(evt);
                                }}
                                name="user_role_function_delete"
                                size="small"
                                disabled={!updatePermission}
                              />
                            }
                            label={<span style={{ fontSize: 12 }}>Delete</span>}
                          />
                          <FormControlLabel
                            value={!row.user_role_function_print}
                            control={
                              <Checkbox
                                checked={row.user_role_function_print}
                                id={row.id}
                                onChange={(evt) => {
                                  handleProductTable(evt);
                                }}
                                name="user_role_function_print"
                                size="small"
                                disabled={!updatePermission}
                              />
                            }
                            label={<span style={{ fontSize: 12 }}>Print</span>}
                          />
                        </TableCell>
                        {/* <TableCell align='right' style={{ fontSize: 12 }}>
                        {row.created_by}
                      </TableCell>
                      <TableCell align='right' style={{ fontSize: 12 }}>
                        {row.modified_by}
                      </TableCell> */}
                        <TableCell
                          align="center"
                          style={{
                            fontSize: 12,
                          }}
                          padding="checkbox"
                        >
                          {/* <Tooltip title='Edit'>
                            <IconButton
                              aria-label='delete'
                              size='small'
                              onClick={() => {
                                setOpenEditPopup(true);
                                setItem(row);
                                console.log(row);
                              }}
                            >
                              <EditIcon fontSize='small' />
                            </IconButton>
                          </Tooltip> */}

                          <Tooltip title="Delete">
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={() => {
                                setId(row.id);
                                setOpenDeletePopup(true);
                                setItem(row);
                              }}
                              disabled={!deletePermission}
                            >
                              <DeleteIcon
                                fontSize="small"
                                style={{ fill: "red" }}
                              />
                            </IconButton>
                          </Tooltip>

                          {/* <BootstrapTooltip
                            title={
                              <div>
                                <p>Created By : {row.created_by}</p>
                                <p>Created Date : {row.created_date}</p>
                                <p>Modified By : {row.modified_by}</p>
                                <p>Modified Date : {row.modified_date}</p>
                              </div>
                            }
                          >
                            <IconButton aria-label='info' size='small'>
                              <InfoIcon
                                fontSize='small'
                                style={{ fill: '	#ffcc00' }}
                              />
                            </IconButton>
                          </BootstrapTooltip> */}
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow className={classes.tableBody}>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <p style={{ textAlign: "center" }}>No Data Available!</p>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div>
          <Grid container>
            <Grid
              item
              xs={5}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                height: 30,
                marginTop: "1%",
              }}
            >
              {toggleButton ? (
                <div>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={cancelHandler}
                    style={{ marginRight: 10, marginLeft: 20 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={submitHandler}
                  >
                    Save
                  </Button>
                </div>
              ) : null}
            </Grid>
            <Grid item xs={7}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                component="div"
                count={userRoleFunctions.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Grid>
          </Grid>
        </div>
      </Paper>
      <DeletePopup
        setOpenDeletePopup={setOpenDeletePopup}
        openDeletePopup={openDeletePopup}
        id={id}
        item={item}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default EnhancedTable;
