import {
  MEASUREMENT_REQUEST,
  MEASUREMENT_SUCCESS,
  MEASUREMENT_FAIL,
  MEASUREMENT_CREATE_REQUEST,
  MEASUREMENT_CREATE_SUCCESS,
  MEASUREMENT_CREATE_FAIL,
  MEASUREMENT_CREATE_RESET,
  MEASUREMENT_UPDATE_REQUEST,
  MEASUREMENT_UPDATE_SUCCESS,
  MEASUREMENT_UPDATE_FAIL,
  MEASUREMENT_UPDATE_RESET,
  MEASUREMENT_DELETE_REQUEST,
  MEASUREMENT_DELETE_SUCCESS,
  MEASUREMENT_DELETE_FAIL,
} from "../constants/constants";

export const measurementReducer = (
  state = { measurementState: [] },
  action
) => {
  switch (action.type) {
    case MEASUREMENT_REQUEST:
      return {
        loading: true,
        measurementState: [],
      };
    case MEASUREMENT_SUCCESS:
      return {
        loading: false,
        measurementState: action.payload,
      };
    case MEASUREMENT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const measurementCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case MEASUREMENT_CREATE_REQUEST:
      return { loading: true };
    case MEASUREMENT_CREATE_SUCCESS:
      return { loading: false, measurementCreates: action.payload };
    case MEASUREMENT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case MEASUREMENT_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const measurementUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case MEASUREMENT_UPDATE_REQUEST:
      return { loading: true };
    case MEASUREMENT_UPDATE_SUCCESS:
      return { loading: false, measurementUpdates: action.payload };
    case MEASUREMENT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case MEASUREMENT_UPDATE_RESET:
      return { measurementCreates: {} };
    default:
      return state;
  }
};

export const measurementDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case MEASUREMENT_DELETE_REQUEST:
      return { loading: true };
    case MEASUREMENT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case MEASUREMENT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
