export const SHIFT_REQUEST = "SHIFT_REQUEST";
export const SHIFT_SUCCESS = "SHIFT_SUCCESS";
export const SHIFT_FAIL = "SHIFT_FAIL";

export const SHIFT_CREATE_REQUEST = "SHIFT_CREATE_REQUEST";
export const SHIFT_CREATE_SUCCESS = "SHIFT_CREATE_SUCCESS";
export const SHIFT_CREATE_FAIL = "SHIFT_CREATE_FAIL";
export const SHIFT_CREATE_RESET = "SHIFT_CREATE_RESET";

export const SHIFT_UPDATE_REQUEST = "SHIFT_UPDATE_REQUEST";
export const SHIFT_UPDATE_SUCCESS = "SHIFT_UPDATE_SUCCESS";
export const SHIFT_UPDATE_FAIL = "SHIFT_UPDATE_FAIL";
export const SHIFT_UPDATE_RESET = "SHIFT_UPDATE_RESET";

export const SHIFT_DELETE_REQUEST = "SHIFT_DELETE_REQUEST";
export const SHIFT_DELETE_SUCCESS = "SHIFT_DELETE_SUCCESS";
export const SHIFT_DELETE_FAIL = "SHIFT_DELETE_FAIL";

export const SHIFT_PERMISSION_REQUEST = "SHIFT_PERMISSION_REQUEST";
export const SHIFT_PERMISSION_SUCCESS = "SHIFT_PERMISSION_SUCCESS";
export const SHIFT_PERMISSION_FAIL = "SHIFT_PERMISSION_FAIL";
