export const ACCOUNT_REQUEST = "ACCOUNT_REQUEST";
export const ACCOUNT_SUCCESS = "ACCOUNT_SUCCESS";
export const ACCOUNT_FAIL = "ACCOUNT_FAIL";

export const ACCOUNT_CREATE_REQUEST = "ACCOUNT_CREATE_REQUEST";
export const ACCOUNT_CREATE_SUCCESS = "ACCOUNT_CREATE_SUCCESS";
export const ACCOUNT_CREATE_FAIL = "ACCOUNT_CREATE_FAIL";
export const ACCOUNT_CREATE_RESET = "ACCOUNT_CREATE_RESET";

export const ACCOUNT_UPDATE_REQUEST = "ACCOUNT_UPDATE_REQUEST";
export const ACCOUNT_UPDATE_SUCCESS = "ACCOUNT_UPDATE_SUCCESS";
export const ACCOUNT_UPDATE_FAIL = "ACCOUNT_UPDATE_FAIL";
export const ACCOUNT_UPDATE_RESET = "ACCOUNT_UPDATE_RESET";

export const ACCOUNT_DELETE_REQUEST = "ACCOUNT_DELETE_REQUEST";
export const ACCOUNT_DELETE_SUCCESS = "ACCOUNT_DELETE_SUCCESS";
export const ACCOUNT_DELETE_FAIL = "ACCOUNT_DELETE_FAIL";

export const ACCOUNT_PERMISSION_REQUEST = "ACCOUNT_PERMISSION_REQUEST";
export const ACCOUNT_PERMISSION_SUCCESS = "ACCOUNT_PERMISSION_SUCCESS";
export const ACCOUNT_PERMISSION_FAIL = "ACCOUNT_PERMISSION_FAIL";

//USER
export const USER_REQUEST = "USER_REQUEST";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAIL = "USER_FAIL";
