import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TableComponent from "./TableComponent";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { designationList } from "../../../redux/actions/actions";
import { permissionAction } from "../../../redux/actions/permissionActions";

import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Icon from "@material-ui/core/Icon";

const TableMain = (props) => {
  const { userData } = props;
  const dispatch = useDispatch();
  const [app_menu_id, setAppMenuId] = useState(17);

  const designation = useSelector((state) => state.designation);

  const { designations } = designation;

  const permission = useSelector((state) => state.permission);

  const { permissions } = permission;

  console.log("permission - xyz", permissions);

  console.log("from main-user:", userData);
  console.log("from main:", designations);

  useEffect(() => {
    dispatch(designationList());
    dispatch(permissionAction(userData, app_menu_id));
  }, [dispatch, userData]);

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Icon style={{ fontSize: 25, marginTop: 18 }}>badge</Icon>
        <h2 style={{ marginLeft: 5 }}>Designation</h2>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: -30,
          fontSize: 11,
          marginLeft: 25,
        }}
      >
        <p>Settings</p>

        <NavigateNextIcon style={{ fontSize: 14, marginTop: 12 }}>
          navigate_next
        </NavigateNextIcon>
        <p> Designation</p>
      </div>

      <div style={{ marginTop: 20 }}>
        <TableComponent
          designations={designations}
          permissions={permissions}
        ></TableComponent>
      </div>
    </div>
  );
};

export default TableMain;
